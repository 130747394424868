import { combineReducers } from "redux";

import onboardingReducer from "./onboarding";
import resultsReducer from "./results";

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

const appReducer = combineReducers({
  onboardingReducer,
  resultsReducer,
});

export default rootReducer;
