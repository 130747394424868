import * as types from "../../action-constants/onboarding";

const initialState = {
  candidateInfo: {},
  candidateResults: {},
  loginSuccess: false,
  loginFailure: "",
  loginPending: false,
  loadingResults: false,
  loadingInvoice: false,
};

export default function onboardingReducers(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_USER_SUCCESS:
      return {
        ...state,
        candidateInfo: action.payload,
        loginSuccess: true,
        loading: false,
      };
    case types.CANDIDATE_RESULTS:
      return {
        ...state,
        candidateResults: { ...state.candidateResults, ...action.payload },
      };

    case types.LOGIN_USER_FAILURE:
      return {
        ...state,
        loginFailure: action.payload,
      };
    case types.LOGIN_USER_PENDING:
      return {
        ...state,
        loginPending: action.payload,
      };
    case types.LOADING_RESULTS:
      return {
        ...state,
        loadingResults: action.payload,
      };
    case types.LOGOUT:
      return initialState;
    case types.LOADING_INVOICE:
      return {
        ...state,
        loadingInvoice: action.payload,
      };
    case types.PAYMENT_INVOICE:
      return {
        ...state,
        invoice: action.payload,
      };
    default:
      return state;
  }
}
