import React from "react";
import { InAppTemplate } from "../../components/templates/portal";
import ResultsForm from "./ResultsForm";

const OlevelResults = () => {
  return (
    <InAppTemplate
      childComponent={<ResultsForm />}
      pageTitle="POST UTME/DE RESULT SCREENING PORTAL"
    />
  );
};

export default OlevelResults;
