import UTME from "../../assets/UTME.png";
import LogOutImg from "../../assets/Logout-rounded.png";
import Document from "../../assets/Documents.png";
import Passed from "../../assets/Test-Passed.png";
import PaymentIcon from "../../assets/card-pay.png";
import Print from "../../assets/Print.png";
export const subjects = [
  { value: "Accounting", label: "Accounting" },
  { value: "Agricultural Science", label: "Agricultural Science" },
  { value: "Animal Husbandry", label: "Animal Husbandry" },
  { value: "Arabic Language", label: "Arabic Language" },
  { value: "Basic Electricity", label: "Basic Electricity" },
  { value: "Biology", label: "Biology" },
  { value: "Book Keeping", label: "Book Keeping" },
  {
    value: "Brick Laying and Block Laying",
    label: "Brick Laying and Block Laying",
  },
  {
    value: "Building And Engineering Drawing",
    label: "Building And Engineering Drawing",
  },
  {
    value: "Cable Joint And Battery Charge",
    label: "Cable Joint And Battery Charge",
  },
  { value: "Chemistry", label: "Chemistry" },
  { value: "Civic Education", label: "Civic Education" },
  { value: "Class Teaching", label: "Class Teaching" },
  { value: "Commerce", label: "Commerce" },
  { value: "Computer Studies", label: "Computer Studies" },
  { value: "Concreating", label: "Concreating" },
  { value: "CRK", label: "CRK" },
  {
    value: "Domestic And Industrial Installation",
    label: "Domestic And Industrial Installation",
  },
  { value: "Economics", label: "Economics" },
  { value: "Education", label: "Education" },
  {
    value: "Electrical Installation and maintenance",
    label: "Electrical Installation and maintenance",
  },
  {
    value: "Electronic Device and Circuit",
    label: "Electronic Device and Circuit",
  },
  { value: "English", label: "English" },
  { value: "English Literature", label: "English Literature" },
  { value: "Fine Arts", label: "Fine Arts" },
  { value: "Fishery", label: "Fishery" },
  {
    value: "Fitting, Drilling and Grinding",
    label: "Fitting, Drilling and Grinding",
  },
  { value: "Food And Nutrition", label: "Food And Nutrition" },
  { value: "French", label: "French" },
  { value: "Further Mathematics", label: "Further Mathematics" },
  { value: "General Metal Work", label: "General Metal Work" },
  { value: "Geography", label: "Geography" },
  { value: "Government", label: "Government" },
  { value: "Hausa Language", label: "Hausa Language" },
  { value: "Hausa Literature", label: "Hausa Literature" },
  { value: "Health Science", label: "Health Science" },
  { value: "History", label: "History" },
  { value: "Home Economics", label: "Home Economics" },
  { value: "Home Management", label: "Home Management" },
  { value: "Igbo Language", label: "Igbo Language" },
  {
    value: "Information And Communication Tech.",
    label: "Information And Communication Tech.",
  },
  { value: "Int Science", label: "Int Science" },
  {
    value: "Intro to Building Construction",
    label: "Intro to Building Construction",
  },
  { value: "IRK", label: "IRK" },
  { value: "Marketing", label: "Marketing" },
  { value: "Mathematics", label: "Mathematics" },
  {
    value: "Mechanical Engineering Craft Practice",
    label: "Mechanical Engineering Craft Practice",
  },
  { value: "Motor Vehicle M. Works", label: "Motor Vehicle M. Works" },
  { value: "Music", label: "Music" },
  { value: "Office Practice", label: "Office Practice" },
  { value: "P.H.E.", label: "P.H.E." },
  { value: "Physics", label: "Physics" },
  { value: "Radio Communication", label: "Radio Communication" },
  { value: "Short Hand", label: "Short Hand" },
  { value: "Social Studies", label: "Social Studies" },
  { value: "Technical Drawing", label: "Technical Drawing" },
  { value: "Television", label: "Television" },
  {
    value: "Turning, Milling, Shaping, Planning, and Slotting",
    label: "Turning, Milling, Shaping, Planning, and Slotting",
  },
  { value: "Typewriting", label: "Typewriting" },
  { value: "Visual Arts", label: "Visual Arts" },
  {
    value: "Walls, Floors and Ceiling Finishing",
    label: "Walls, Floors and Ceiling Finishing",
  },
  {
    value: "Winding Of Electrical Machine",
    label: "Winding Of Electrical Machine",
  },
  { value: "Wood Work", label: "Wood Work" },
  { value: "Yoruba Language", label: "Yoruba Language" },
];

export const A_LEVEL_RESULTS = [
  { value: "AD", label: "AD - Advanced Diploma" },
  { value: "Degree", label: "Degree" },
  { value: "HND", label: "HND - Higher National Diploma" },
  { value: "IJMB", label: "IJMB - Interim Joint Matriculation Board" },
  { value: "NCE", label: "National Certificate in Education" },
  { value: "ND", label: "ND - National Diploma" },
  { value: "OND", label: "OND - Ordinary National Diploma" },
];

export const CLASS_OF_GRADUATION = [
  { label: "DISTINCTION", value: "DISTINCTION" },
  { label: "UPPER CREDIT", value: "UPPER CREDIT" },
  { label: "LOWER CREDIT", value: "LOWER CREDIT" },
  { label: "PASS", value: "PASS" },
  { label: "A.R", value: "A.R" },
  { label: "MERIT", value: "MERIT" },
];

export const grades = ["A", "B", "C", "D", "E", "F", "A.R"];

export const DEGREE_CLASS_OF_GRADUATION = [
  { label: "FIRST CLASS", value: "FIRST CLASS" },
  { label: "SECOND CLASS UPPER", value: "SECOND CLASS UPPER" },
  { label: "SECOND CLASS LOWER", value: "SECOND CLASS LOWER" },
  { label: "THIRD CLASS", value: "THIRD CLASS" },
  { label: "PASS", value: "PASS" },
];

export const examWithGrades = {
  WAEC: ["A1", "B2", "B3", "C4", "C5", "C6", "AR"],
  NECO: ["A1", "B2", "B3", "C4", "C5", "C6", "AR"],
  NABTEB: ["A1", "A2", "A3", "C4", "C5", "C6", "AR"],
  NBAIS: ["A", "B2", "B3", "C4", "C5", "C6", "AR"],
  "GRADE II TEACHERS CERT.": ["A", "B", "B1", "B2", "C", "D", "AR"],
};
const currentYear = new Date().getFullYear();
const startYear = 1989;

export const yearsArray = Array.from(
  { length: currentYear - startYear + 1 },
  (_, index) => startYear + index
).map((year) => ({ value: year, label: year }));

export const examTypes = [
  { value: "WAEC", label: "WAEC" },
  { value: "NECO", label: "NECO" },
  { value: "GRADE II TEACHERS CERT.", label: "GRADE II TEACHERS CERT." },
  { value: "NABTEB", label: "NABTEB" },
  { value: "NBAIS", label: "NBAIS" },
];

export const examMonthsArray = [
  { value: "MAY/JUN", label: "MAY/JUN" },
  { value: "JUN/JUL", label: "JUN/JUL" },
  { value: "OCT/NOV", label: "OCT/NOV" },
  { value: "NOV/DEC", label: "NOV/DEC" },
];

const sideBarMenu = {
  LOGOUT: "/",
  DATA: "/info",
  RESULTS: "/results",
  A_LEVEL_RESULTS: "/alevelresults",
  UPLOADS: "/uploads",
  PAYMENT: "/payment",
  ACKNOWLEDGEMENT: "/acknowledgment",
};

export const sideMenu = [
  {
    name: "UTME Data",
    icon: UTME,
    routeTo: sideBarMenu.DATA,
  },
  {
    name: "O'level Results",
    icon: Passed,
    routeTo: sideBarMenu.RESULTS,
  },
  {
    name: "A'level Results",
    icon: Passed,
    routeTo: sideBarMenu.A_LEVEL_RESULTS,
  },
  {
    name: "Documents Upload",
    icon: Document,
    routeTo: sideBarMenu.UPLOADS,
  },
  {
    name: "Payment",
    icon: PaymentIcon,
    routeTo: sideBarMenu.PAYMENT,
  },
  {
    name: "Print Acknowledgment",
    icon: Print,
    routeTo: sideBarMenu.ACKNOWLEDGEMENT,
  },
  {
    name: "Logout",
    icon: LogOutImg,
    routeTo: "/",
  },
];
