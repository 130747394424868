export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_PENDING = "LOGIN_USER_PENDING";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";
export const LOGIN_USER_RESET = "LOGIN_USER_RESET";

export const LOGOUT = "LOGOUT";

export const CANDIDATE_RESULTS = "CANDIDATE_RESULTS";
export const LOADING_RESULTS = "LOADING_RESULTS";
export const UPLOAD_LOADING = "UPLOAD_LOADING";
export const PAYMENT_INVOICE = "PAYMENT_INVOICE";
export const LOADING_INVOICE = "LOADING_INVOICE";
