import React from "react";
import Header from "../LandingPage/Header";
import "./index.scss";

const FAQs = () => {
  return (
    <div className="landing-page-container">
      <div className="landing-page">
        <Header pageTitle="F.A.Q on Post UTME/DE Result Submission" />
        <div className="main-section">
          <div className="main-section-heading">
            <div className="black-dot dot"></div>{" "}
            <h5>Having Problem Or Need Clarification?</h5>
          </div>
          <div className="main-section-content">
            <p>
              If you have any problem on the portal, just go through the
              following FAQ, you might find an answer to your problem. If this
              page is not helpful, then use the contact page to submit your
              complain or visit the I.C.T Unit of the University for further
              assistance.
            </p>
            <div className="questions">
              <strong>
                (Q 1.) I have checked the eligibility status and i am eligible
                to submit my O'Level examination result(s). What is the next
                step for me to take?
              </strong>
              <p>
                <strong>Answer :</strong> You must generate your transaction
                ID/print out the eligibility confirmation/bank payment slip
                which contains your basic JAMB information and further
                instructions on how you should proceed. You should then proceed
                to payment by either going to the banks listed on the portal and
                make the appropriate payment or you can pay online using your
                ATM card. After your payment, allow for few minutes to receive a
                text message before returning to portal to continue with the
                online result submission exercise. Log in using your JAMB Reg.
                No and your phone no to submit your result(s) . Click on the
                INSTRUCTIONS link for a detailed guideline.
              </p>

              <strong>
                (Q 2.) I have checked my eligibility and have made payment but
                don't know how to log in and submit my result(s).
              </strong>
              <p>
                <strong>Answer :</strong> Your payment must be confirmed and
                your account activated by the University first before you can
                log in and submit your result(s). Click on the INSTRUCTIONS link
                for a detailed guideline.
              </p>

              <strong>
                (Q 3.) I have submitted my result(s) but want to change some
                information on my submission. Can i edit my result(s) submission
                ?
              </strong>
              <p>
                <strong>Answer :</strong> Yes, you can come back for an
                unlimited number of times to edit and re-submit your result(s)
                as long as it is within the submission period.
              </p>

              <strong>
                (Q 4.) If i forgot my JAMB - UTME/DE registration number, can i
                retrieve them ?
              </strong>
              <p>
                <strong>Answer :</strong> Yes, you can retrieve your JAMB -
                UTME/DE registration number by reporting to JAMB and not the
                university.
              </p>

              <strong>
                (Q 5.) I keep on getting an error message when i attempted to
                log in, What does this mean ?
              </strong>
              <p>
                <strong>Answer :</strong> It means your payment is not confirmed
                and your account not yet activated on the portal. You should
                allow for few minutes and must have receive a text message after
                making payment before you can continue. If you are sure that you
                have waited for the period of time, then contact the university
                via email or phone with your eligibility acknowledgment slip and
                the teller to validate and or confirmed the payment or call any
                of the help lines.
              </p>

              <strong>
                (Q 6.) I keep on getting an error message "The name XXXXXXXX is
                already taken.". What does this mean ?
              </strong>
              <p>
                <strong>Answer :</strong> It means the JAMB No you are
                attempting to use has already been used by another user. Contact
                the ICT unit of the university by using the contact form on the
                university main website.
              </p>

              <strong>
                (Q 6.) Is there any Special arrangements for students with
                disabilities to use the online portal ?
              </strong>
              <p>
                <strong>Answer :</strong> Special arrangements can be made for
                candidates who, due to a disability, cannot use the online
                service. Candidates who fall into this category should contact
                the I.C.T unit in person.
              </p>
            </div>
          </div>
        </div>
        <div className="footer">
          <p>Ibrahim Badamasi Babangida University</p>
        </div>
      </div>
    </div>
  );
};

export default FAQs;
