import React, { useEffect, useMemo, useState } from "react";
import { CiCirclePlus } from "react-icons/ci";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { Table } from "react-bootstrap";
import "react-loading-skeleton/dist/skeleton.css";
import DropDown from "../../components/dropdown";
import "./index.scss";
import Remove from "../../assets/Remove.png";

import {
  subjects,
  examWithGrades,
  examTypes,
  yearsArray,
  examMonthsArray,
} from "../../shared/_helpers/constants";
import { useSelector, useDispatch } from "react-redux";
import {
  addOrUpdateResult,
  candidateLogin,
  deleteResult,
  getCandidateResults,
  getResults,
  postUmeLogin,
} from "../../redux/action/onboarding";
import { specialCharactersRegex, emailRegex } from "../../shared/utils";

const ResultsForm = () => {
  const dispatch = useDispatch();
  const { candidateInfo, candidateResults, loadingResults } = useSelector(
    (state) => state.onboardingReducer
  );

  const { oLevelResult1, oLevelResult2 } = candidateResults || {};
  const {
    registrationNumber,
    scratchCardSerialNumber1,
    scratchCardPin1,
    scratchCardSerialNumber2,
    scratchCardPin2,
    email: userEmail,
    phoneNumber: userPhone,
  } = candidateInfo;
  const [subject, setSubject] = useState(null);
  const [grade, setGrade] = useState(null);
  const [year, setYear] = useState(null);
  const [examType, setExamType] = useState(null);
  const [examNumber, setExamNumber] = useState("");
  const [examRecords, setExamRecords] = useState([]);
  const [uniqueExamParameter, setUniqueExamParameter] = useState([]);
  const [examMonth, setExamMonth] = useState(null);
  const [loading, setLoading] = useState(false);
  const [examNumberError, setExamNumberError] = useState(false);

  const [email, setEmail] = useState(userEmail || "");
  const [emailError, setEmailError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(userPhone || "");
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const [cards, setCards] = useState({
    scratchCardSerialNumber1: "",
    scratchCardPin1: "",
    scratchCardSerialNumber2: "",
    scratchCardPin2: "",
  });
  const [cardsError, setCardsError] = useState({
    scratchCardSerialNumber1Error: false,
    scratchCardPin1Error: false,
    scratchCardSerialNumber2Error: false,
    scratchCardPin2Error: false,
  });

  const formatedExamGrades = useMemo(
    () =>
      examWithGrades[examType]?.map((grade) => ({
        label: grade,
        value: grade,
      })) || [],
    [examType]
  );

  const handleCardChange = (e) => {
    const { name, value } = e.target;
    setCardsError((prevState) => ({
      ...prevState,
      [`${name}Error`]: specialCharactersRegex.test(value),
    }));

    setCards((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleExamTypeChange = (value) => {
    if (value === "NECO") {
      setExamMonth({ label: "JUN/JUL", value: "JUN/JUL" });
    } else {
      setExamMonth(null);
    }
    setExamType(value);
    setGrade(null);
  };
  const handleAddRecord = () => {
    const uniqueString = `${examMonth?.value}${year}${examNumber}`;
    const newRecord = {
      subject,
      grade,
      year,
      examMonth: examMonth?.value,
      examType,
      examNumber,
      uniqueString,
    };
    const recordGrade = grade?.value;
    if (
      !subject ||
      !recordGrade ||
      !year ||
      !examType ||
      !examNumber ||
      !examMonth?.value
    ) {
      toast.error("Please fill in all required fields.");
      return;
    }
    if (specialCharactersRegex.test(examNumber)) {
      return toast.error("Exam number cannot contain special characters.");
    }
    // This is for validation, a candidate (the same candidate) should not be able to submit 1 Exam type + Exam Year + Exam Month with different Exam numbers. e.g Example candidate 202412345678GH submitting: Result1: WAEC 2023 MAY/JUNE EXAM NO:123456 and Result2: WAEC 2023 MAY/JUNE EXAM NO: 456123
    const checkUniqueStringCombinationWithExamNumber = examRecords.some(
      (exam) =>
        `${exam.examType}${exam.year}${exam.examMonth}` ===
          `${examType}${year}${examMonth?.value}` &&
        exam.examNumber !== examNumber
    );
    const existingSubject = examRecords?.some(
      (record) =>
        record?.subject === subject && record.uniqueString === uniqueString
    );

    if (existingSubject) {
      return toast.warning("Subject record exists");
    }
    if (!uniqueExamParameter.includes(uniqueString)) {
      if (uniqueExamParameter.length === 2) {
        toast.error("Maximum of two sittings allowed.");
        return;
      } else if (checkUniqueStringCombinationWithExamNumber) {
        return toast.error(
          "You cannot have different exam number for same exam in the same year."
        );
      } else if (
        uniqueExamParameter?.length < 2 &&
        !checkUniqueStringCombinationWithExamNumber &&
        !existingSubject
      ) {
        setUniqueExamParameter((prevNumbers) => [...prevNumbers, uniqueString]);
      } else if (existingSubject) {
        return toast.warning("Subject record exists");
      }
    }

    setExamRecords((prevRecords) => [
      ...prevRecords,
      { ...newRecord, grade: recordGrade },
    ]);
  };
  const handleDeleteRecord = (index) => {
    const uniqueIdentifier = examRecords[index].uniqueString;
    const uniqueParametersClone = [...uniqueExamParameter];
    let updatedRecords = [];
    updatedRecords = [...examRecords];
    updatedRecords.splice(index, 1);
    setExamRecords(updatedRecords);
    dispatch(getCandidateResults(updatedRecords));
    //check if exam number has any subject(s) in exam record
    const subject = updatedRecords.some(
      (record) => record.uniqueString === uniqueIdentifier
    );
    if (!subject) {
      const uniqueParameterToRemove = uniqueParametersClone.findIndex(
        (uniqueStr) => uniqueStr === uniqueIdentifier
      );

      if (uniqueParameterToRemove > -1) {
        if (uniqueParameterToRemove === 0) {
          dispatch(
            candidateLogin({
              ...candidateInfo,
              scratchCardPin1: cards.scratchCardPin2,
              scratchCardSerialNumber1: cards.scratchCardSerialNumber2,
              scratchCardPin2: "",
              scratchCardSerialNumber2: "",
            })
          );
          setCards({
            scratchCardPin1: cards.scratchCardPin2,
            scratchCardSerialNumber1: cards.scratchCardSerialNumber2,
            scratchCardPin2: "",
            scratchCardSerialNumber2: "",
          });
          setCardsError({
            scratchCardSerialNumber1Error:
              cardsError.scratchCardSerialNumber2Error,
            scratchCardPin1Error: cardsError.scratchCardPin2Error,
            scratchCardSerialNumber2Error: false,
            scratchCardPin2Error: false,
          });
        }

        uniqueParametersClone.splice(uniqueParameterToRemove, 1);
        setUniqueExamParameter(uniqueParametersClone);
      }
    }
    if (examRecords[index].oLevelResultId) {
      deleteResult(registrationNumber, examRecords[index].oLevelResultId);
    }
  };
  function transformExamRecords(registrationNumber, examRecords) {
    const cardClone = { ...cards };

    for (const key in cardClone) {
      if (cardClone[key] === null) {
        cardClone[key] = "";
      } else {
        cardClone[key] = cardClone[key]?.replace(/\s+/g, "");
      }
    }
    const transformedObject = {
      registrationNumber,
      ...cardClone,
      email,
      phoneNumber,
    };

    const uniqueEntries = [
      ...new Set(examRecords.map((record) => record.uniqueString)),
    ];
    uniqueEntries.forEach((uniqueExam, index) => {
      const resultKey = `oLevelResult${index + 1}`;
      const recordsForUniqueEntry = examRecords.filter(
        (record) => record.uniqueString === uniqueExam
      );
      transformedObject[resultKey] = recordsForUniqueEntry.map((record) => ({
        oLevelResultId: record.oLevelResultId || "",
        subject: record.subject,
        grade: record.grade,
        examType: record.examType,
        examNumber: record.examNumber,
        year: `${record.year}`,
        examMonth: record.examMonth,
        uniqueString: record.uniqueString,
      }));
    });
    if (uniqueEntries.length < 2) {
      transformedObject.oLevelResult2 = [];
    }

    return transformedObject;
  }

  const handleSubmit = async () => {
    let requiredCardFields;
    const uniqueExamCount = uniqueExamParameter.length;
    if (uniqueExamCount === 1) {
      requiredCardFields = ["scratchCardPin1"];
    } else if (uniqueExamCount === 2) {
      requiredCardFields = ["scratchCardPin1", "scratchCardPin2"];
    }
    const cardFields = [
      "scratchCardPin1",
      "scratchCardPin2",
      "scratchCardSerialNumber1",
      "scratchCardSerialNumber2",
    ];

    const areAllCardFieldsFilled = requiredCardFields.every(
      (field) => cards[field]
    );

    if (!areAllCardFieldsFilled) {
      setLoading(false);
      return toast.error("Please provide all scratch card information.");
    }
    if (!email.length || !emailRegex.test(email)) {
      return toast.error("Please provide a valid email address.");
    }
    if (!phoneNumber || !isValidPhoneNumber) {
      return toast.error("Please provide a valid phone number.");
    }
    const checkForSpecialCharacters = cardFields.some((field) =>
      specialCharactersRegex.test(cards[field])
    );

    if (checkForSpecialCharacters) {
      return toast.error(
        "Scratch card number cannot contain special characters."
      );
    }

    if (
      cards?.scratchCardPin1?.replace(/\s+/g, "") ===
      cards?.scratchCardPin2?.replace(/\s+/g, "")
    ) {
      return toast.error(
        "Each exam requires a unique scratch card PIN. Please enter different PINs for the two exams."
      );
    }
    setLoading(true);
    const transformedObject = transformExamRecords(
      registrationNumber,
      examRecords
    );
    const response = await addOrUpdateResult(transformedObject);
    if (response.status === 200) {
      setLoading(false);
      dispatch(candidateLogin({ ...candidateInfo, ...cards }));
      await dispatch(postUmeLogin({ registrationNumber, phoneNumber }, false));
      dispatch(getResults(registrationNumber));
      return toast.success("Results records updated successfully");
    }
    setLoading(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError(!emailRegex.test(e.target.value));
  };
  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    setIsValidPhoneNumber(
      /^(?:\+\d{1,3}\s?)?(?:\d\s?){10,14}\d$/.test(e.target.value)
    );
  };

  useEffect(() => {
    dispatch(getResults(registrationNumber));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const allResults = [
      ...(oLevelResult1 ? oLevelResult1 : []),
      ...(oLevelResult2 ? oLevelResult2 : []),
    ];
    if (candidateResults?.oLevelResult1 || candidateResults?.oLevelResult2) {
      const uniqueStrings = [];
      const extractData = allResults.filter((result) => {
        if (
          result?.uniqueString &&
          !uniqueStrings.includes(result.uniqueString)
        ) {
          uniqueStrings.push(result.uniqueString);
        }
        if (result.subject && result.examNumber) {
          return true;
        } else {
          return false;
        }
      });
      setExamRecords(extractData);
      setUniqueExamParameter(uniqueStrings);
    }
    setCards({
      scratchCardSerialNumber1,
      scratchCardPin1,
      scratchCardSerialNumber2,
      scratchCardPin2,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateResults]);
  return (
    <div className="results-container">
      <div className="note">NOTE*:Maximum of 2 sittings</div>
      <div className="select-fields">
        <DropDown
          field="examType"
          options={examTypes}
          label="Exam Type"
          handleChange={handleExamTypeChange}
          className="exam-type"
          placeholder="Exam type..."
        />
        <DropDown
          field="subject"
          options={subjects}
          label="Subject"
          handleChange={setSubject}
          className="subject-field"
          placeholder="Subject..."
        />
        <DropDown
          field="grade"
          options={formatedExamGrades}
          label="Grade"
          handleChange={setGrade}
          value={grade}
          className="grade-field"
          placeholder="Grade..."
        />
        <DropDown
          field="year"
          options={yearsArray}
          label="Year"
          handleChange={setYear}
          className="year-field"
          placeholder="Year..."
        />
        <DropDown
          field="examMonth"
          options={
            examType === "WAEC"
              ? [
                  { label: "MAY/JUN", value: "MAY/JUN" },
                  { label: "NOV/DEC", value: "NOV/DEC" },
                ]
              : examMonthsArray
          }
          label="Exam Month"
          handleChange={setExamMonth}
          value={examMonth}
          className="exam-month-field"
          placeholder="Exam Month..."
        />

        <input
          type="text"
          name="examNumber"
          value={examNumber}
          onChange={(e) => {
            setExamNumber(e.target.value);
            setExamNumberError(specialCharactersRegex.test(e.target.value));
          }}
          placeholder="Exam Number"
          className={
            examNumberError ? "input-error exam-number" : "exam-number"
          }
        />
        <button onClick={handleAddRecord} className="add-result-record">
          <CiCirclePlus style={{ width: "20px", height: "20px" }} /> Add
        </button>
      </div>

      <div className="result-table">
        {loadingResults ? (
          <Table bordered>
            <thead>
              <tr>
                <th style={{ width: "29%" }}>Subject</th>
                <th style={{ width: "10%" }}>Grade</th>
                <th style={{ width: "10%" }}>Year</th>
                <th style={{ width: "15%" }}>Exam Type</th>
                <th style={{ width: "30%" }}>Exam Number</th>
                <th style={{ width: "7%" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {[...Array(8)].map((_, index) => (
                <tr key={index}>
                  <td style={{ width: "29%" }}>
                    {" "}
                    <Skeleton height={20} />
                  </td>
                  <td style={{ width: "10%" }}>
                    {" "}
                    <Skeleton height={20} />
                  </td>
                  <td style={{ width: "10%" }}>
                    {" "}
                    <Skeleton height={20} />
                  </td>
                  <td style={{ width: "15%" }}>
                    {" "}
                    <Skeleton height={20} />
                  </td>
                  <td style={{ width: "30%" }}>
                    {" "}
                    <Skeleton height={20} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          uniqueExamParameter.map((uniqueString, tableIndex) => (
            <div className="table-container" key={tableIndex}>
              <React.Fragment key={tableIndex}>
                <h5>My O level Examination Result({tableIndex + 1})</h5>
                <Table bordered>
                  <thead>
                    <tr>
                      <th style={{ width: "29%" }}>Subject</th>
                      <th style={{ width: "10%" }}>Grade</th>
                      <th style={{ width: "10%" }}>Year</th>
                      <th style={{ width: "10%" }}>Month</th>
                      <th style={{ width: "15%" }}>Exam Type</th>
                      <th style={{ width: "30%" }}>Exam Number</th>
                      <th style={{ width: "7%" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {examRecords
                      .filter((record, idx) => {
                        if (record.uniqueString === uniqueString) {
                          record.index = idx;
                          return true;
                        }
                        return false;
                      })
                      .map((record, index) => (
                        <tr key={index}>
                          <td style={{ width: "29%" }}>{record.subject}</td>
                          <td style={{ width: "10%" }}>{record.grade}</td>
                          <td style={{ width: "10%" }}>{record.year}</td>
                          <td style={{ width: "10%" }}>{record.examMonth}</td>
                          <td style={{ width: "15%" }}>{record.examType}</td>
                          <td style={{ width: "30%" }}>{record.examNumber}</td>
                          <td
                            style={{ width: "7%" }}
                            onClick={() => handleDeleteRecord(record.index)}
                          >
                            <img
                              src={Remove}
                              alt="delete icon"
                              style={{
                                width: "25px",
                                height: "25px",
                                cursor: "pointer",
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </React.Fragment>

              <div className="scratch-card">
                <label htmlFor={`scratchCardPin${tableIndex + 1}`}>
                  Selected Exam Result Scratch Card PIN or TOKEN *
                </label>
                <input
                  type="text"
                  id={`scratchCardPin${tableIndex + 1}`}
                  name={`scratchCardPin${tableIndex + 1}`}
                  value={cards[`scratchCardPin${tableIndex + 1}`]}
                  onChange={handleCardChange}
                  className={
                    cardsError[`scratchCardPin${tableIndex + 1}Error`]
                      ? "input-error scratch-card-input"
                      : "scratch-card-input"
                  }
                />
                {cardsError[`scratchCardPin${tableIndex + 1}Error`] ? (
                  <span style={{ color: "red" }}>
                    Special characters are not allowed
                  </span>
                ) : null}
              </div>
              <div className="scratch-card serial-no">
                <label htmlFor={`scratchCardSerialNumber${tableIndex + 1}`}>
                  Selected Exam Result Scratch Card S/No (For Result Cards Only)
                </label>
                <input
                  type="text"
                  id={`scratchCardSerialNumber${tableIndex + 1}`}
                  name={`scratchCardSerialNumber${tableIndex + 1}`}
                  value={cards[`scratchCardSerialNumber${tableIndex + 1}`]}
                  onChange={handleCardChange}
                  className={
                    cardsError[`scratchCardSerialNumber${tableIndex + 1}Error`]
                      ? "input-error scratch-card-input"
                      : "scratch-card-input"
                  }
                />
                {cardsError[`scratchCardSerialNumber${tableIndex + 1}Error`] ? (
                  <span style={{ color: "red" }}>
                    Special characters are not allowed
                  </span>
                ) : null}
              </div>
            </div>
          ))
        )}
        {examRecords?.length ? (
          <div className="email-address">
            <label htmlFor="email-address">Enter email address*</label>
            <input
              type="email"
              id="email-address"
              name="email"
              value={email}
              onChange={handleEmailChange}
              className={emailError ? "input-error" : ""}
            />
            {emailError ? (
              <span style={{ color: "red" }}>
                Please enter valid email address
              </span>
            ) : null}
          </div>
        ) : null}
        {examRecords?.length ? (
          <div className="email-address">
            <label htmlFor="phone-number">Enter phone number*</label>
            <input
              type="text"
              id="phone-number"
              name="phoneNumber"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              className={
                phoneNumber.length && !isValidPhoneNumber ? "input-error" : ""
              }
            />
            {phoneNumber.length && !isValidPhoneNumber ? (
              <span style={{ color: "red" }}>Invalid phone number</span>
            ) : null}
          </div>
        ) : null}
      </div>
      <ul className="instruction-list">
        <li>
          {" "}
          * ALL CANDIDATES ARE HEREBY INSTRUCTED TO PURCHASE THE SELECTED EXAMS
          RESULT SCRATCH CARD AND PROVIDE THE PIN AND SERIAL NO IN THE FIELDS
          PROVIDED BELOW.
        </li>
        <li>
          * BUY A NEW SCRATCH CARD AND USE ONLY ONCE TO CHECK THAT THE CARD IS
          WORKING.
        </li>
        <li>
          * CANDIDATES ARE FURTHER INSTRUCTED TO SECURELY KEEP THEIR EXAMINATION
          RESULTS SCRATCH CARD AFTER PROVIDING THE PINS ON THE PORTAL.
        </li>
        <li>
          * THEY WILL BE REQUIRED TO PRESENT THE RESULT SCRATCH CARD DURING THE
          PHYSICAL VERIFICATION OF THEIR DOCUMENTS OR AS THE NEED ARISE.
        </li>
        <li>
          * PLEASE NOTE THAT PROVIDING A WRONG OR USED SCRATCH CARD PIN
          AUTOMATICALLY DISQUALIFIES A CANDIDATE.
        </li>
      </ul>
      {examRecords.length ? (
        <div className="submit-btn">
          {" "}
          <button onClick={handleSubmit} disabled={loading}>
            {loading ? "Submitting..." : `CLICK TO SUBMIT`}
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default ResultsForm;
