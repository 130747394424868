import Modal from "react-bootstrap/Modal";
import "./index.scss";
const InfoModal = ({ show, setShow, message }) => {
  return (
    <Modal className="info-modal" show={show} onHide={() => setShow(false)}>
      <Modal.Body>
        <p>{message}</p>
        <button onClick={() => setShow(false)}>Close</button>
      </Modal.Body>
    </Modal>
  );
};

export default InfoModal;
