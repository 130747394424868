import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/logo2.png";
import Back from "../../assets/Go-Back.png";
import Ballons from "../../assets/Ballons.png";
const Header = ({ pageTitle, showFirstSectionImage }) => {
  return (
    <>
      <div className="header">
        <div className="left_wrap">
          <div className="logo-wrap">
            <a href="https://ibbu.edu.ng/" target="_blank" rel="noreferrer">
              <img src={Logo} alt="School Logo" />
            </a>
          </div>
          <div className="head_txt_wrap">
            <div className="portal_mode">Post UTME Screening Portal</div>
            <div className="school_name_txt">
              Ibrahim Badamasi Babangida University
            </div>
          </div>
        </div>
        {showFirstSectionImage ? (
          <NavLink to="/faq">FAQ</NavLink>
        ) : (
          <NavLink to="/">Home</NavLink>
        )}
      </div>
      <div className="module-title">
        <h4>{pageTitle}</h4>
      </div>
      <div className="first-section">
        <a
          className="back-to-website"
          href="https://ibbu.edu.ng/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Back} alt="Go back" /> &nbsp;BACK TO UNIVERSITY WEBSITE
        </a>
        {showFirstSectionImage ? (
          <div className="ballons">
            <img src={Ballons} alt="ballon" />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Header;
