import React, { useEffect } from "react";
import Table from "react-bootstrap/Table";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { AlertMsg } from "../../components/alert-msg";
import { postUmeLogin } from "../../redux/action/onboarding";
import { LoadingItem } from "../../components/loading";

const UtmeInfo = () => {
  const { candidateInfo, loginPending } = useSelector(
    (state) => state.onboardingReducer
  );
  const dispatch = useDispatch();
  const {
    registrationNumber,
    fullName,
    state,
    gender,
    subject1,
    subject2,
    subject3,
    subject4,
    subjectScore1,
    subjectScore2,
    subjectScore3,
    subjectScore4,
    totalScore,
    programme,
    mostPreferredInstitution,
    email,
    phoneNumber,
    hasPaid,
    category,
  } = candidateInfo || {};

  useEffect(() => {
    dispatch(postUmeLogin({ registrationNumber, phoneNumber }, false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {loginPending ? (
        <LoadingItem />
      ) : (
        <>
          <div className="alert-container">
            {!hasPaid && (
              <AlertMsg
                type="error"
                message={
                  "You need to make payment before you can submit or upload your data."
                }
              />
            )}
          </div>
          <h4 className="utme-info-title"> UTME Information</h4>
          <Table bordered className="user-info-table">
            <tbody>
              <tr>
                <td>UTME REG. NO</td>
                <td>{registrationNumber}</td>
              </tr>
              <tr>
                <td>FULL NAME</td>
                <td>{fullName}</td>
              </tr>

              <tr>
                <td>STATE OF ORIGIN</td>
                <td>{state}</td>
              </tr>

              <tr>
                <td>Email</td>
                <td>{email || "N/A"}</td>
              </tr>
              <tr>
                <td>Phone Number</td>
                <td>{phoneNumber || "N/A"}</td>
              </tr>

              <tr>
                <td>GENDER</td>
                <td>{gender || "N/A"}</td>
              </tr>
            </tbody>
          </Table>

          {category === "UTME" ? (
            <Table bordered className="user-info-table">
              <tbody>
                <tr>
                  <td>{subject1}</td>
                  <td>{subjectScore1}</td>
                </tr>
                <tr>
                  <td>{subject2}</td>
                  <td>{subjectScore2}</td>
                </tr>

                <tr>
                  <td>{subject3}</td>
                  <td>{subjectScore3}</td>
                </tr>

                <tr>
                  <td>{subject4}</td>
                  <td>{subjectScore4}</td>
                </tr>

                <tr>
                  <td>TOTAL SCORE</td>
                  <td>{totalScore}</td>
                </tr>
              </tbody>
            </Table>
          ) : null}

          <Table bordered className="user-info-table">
            <tbody>
              <tr>
                <td>MOST PREFERRED INSTITUTION</td>
                <td>{mostPreferredInstitution || "N/A"}</td>
              </tr>

              <tr>
                <td>COURSE</td>
                <td>{programme}</td>
              </tr>
            </tbody>
          </Table>
        </>
      )}
    </>
  );
};

export default UtmeInfo;
