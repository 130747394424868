import * as types from "../../action-constants/results";

const initialState = {};

export default function resultsReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_STUDENTS_ADMISSION_LIST:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };

    case "LOGOUT":
      return initialState;
    default:
      return state;
  }
}
