import React from "react";
import SideBar from "../../../components/sidebar";
import { AppHeader } from "../../../components/header";
import useWindowSize from "../../../hooks/useWindowSize";
import "./index.scss";
import DocsUploaded from "../../../modules/DocUploaded";

export const InAppTemplate = (props) => {
  const screenResolution = useWindowSize();
  const { childComponent, noSideBar, pageTitle } = props;
  return (
    <div className="page-main-content">
      <AppHeader />
      <div className="module-title">
        <h4>{pageTitle || ""}</h4>
      </div>

      <DocsUploaded />
      <div className="content-wrap">
        {screenResolution?.width >= 768 && !noSideBar && <SideBar />}
        <div className="middle-content">{childComponent}</div>
      </div>
    </div>
  );
};
