import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AlertMsg } from "../../components/alert-msg";
import Logo from "../../assets/logo2.png";
import { Link } from "react-router-dom";
import useWindowSize from "../../hooks/useWindowSize";

import "./index.scss";
import {
  checkCandidateEligibility,
  Logout,
  postUmeLogin,
  updatePhoneNumber,
} from "../../redux/action/onboarding";

const Login = () => {
  const [isEligible, setIsEligible] = useState(false);
  const [candidatePhoneNumber, setCandidatePhoneNumber] = useState("");
  const formikRef = useRef(null);
  const screenResolution = useWindowSize();
  const dispatch = useDispatch();
  const { loginFailure, loginPending } = useSelector(
    (state) => state.onboardingReducer
  );

  let yearTxt = new Date().getFullYear();

  let checkValidationSchema = Yup.object().shape({
    registrationNumber: Yup.string().required("JAMB Registration is required"),
    phoneNumber: Yup.string()
      .test("phonNumber-required", "Phone number is required", (value) => {
        const phoneNumberIsRequired = isEligible;
        if (phoneNumberIsRequired) {
          return !!value;
        }
        return true;
      })
      .matches(
        /^(?:\+\d{1,3}\s?)?(?:\d\s?){10,14}\d$/,
        "Phone number is not valid."
      ),
  });
  const initialValues = {
    registrationNumber: "",
    phoneNumber: "",
    isEligible,
  };

  useEffect(() => {
    if (candidatePhoneNumber && formikRef.current) {
      formikRef.current.setFieldValue("phoneNumber", candidatePhoneNumber);
    }
  }, [candidatePhoneNumber]);

  useEffect(() => {
    dispatch(Logout());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="onboarding_wrap">
      <div className="login-form-wrapper">
        <div className="onboarding_content splitted">
          {screenResolution?.width >= 767 && (
            <div className="each_side summary">
              <div>
                <div className="logo">
                  <a
                    href="https://ibbu.edu.ng/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Logo} alt="Admission Logo" />
                  </a>
                </div>
                <div className="school_name">
                  IBRAHIM BADAMASI BABANGIDA UNIVERSITY
                </div>
                <div className="student_type_txt">
                  <p>POST UTME/DE</p>
                  <p>O'Level RESULT</p> <p>SCREENING PORTAL</p>
                </div>
              </div>
            </div>
          )}
          {screenResolution?.width <= 766 && (
            <div className="mobile_heading">
              <div className="logo">
                <img src={Logo} alt="" />
              </div>
              <div className="school_name">
                IBRAHIM BADAMASI BABANGIDA UNIVERSITY
              </div>
            </div>
          )}
          <div className="each_side form_info">
            <div className="form_heading">POST UTME/DE</div>
            <div className="form_heading">O'Level RESULT</div>
            <div className="form_heading">SCREENING PORTAL</div>
            {isEligible && !candidatePhoneNumber ? (
              <p className="phoneNumber-note">
                Note: Please provide a valid phone number. This will be used as
                your password for subsequent logins.
              </p>
            ) : null}
            <Formik
              innerRef={formikRef}
              initialValues={{ ...initialValues, isEligible }}
              validationSchema={checkValidationSchema}
              onSubmit={async (values) => {
                let { registrationNumber, phoneNumber: phoneNumberInput } =
                  values;
                let payload = {
                  registrationNumber: registrationNumber.trim(),
                };
                if (!phoneNumberInput?.length && !isEligible) {
                  const res = await dispatch(
                    checkCandidateEligibility(payload)
                  );
                  if (
                    res?.status === 200 &&
                    res?.data?.registrationNumber?.length
                  ) {
                    setIsEligible(true);
                    if (res?.data?.phoneNumber) {
                      setCandidatePhoneNumber(res?.data?.phoneNumber);
                    }
                  }
                } else if (phoneNumberInput?.length) {
                  payload = {
                    registrationNumber: registrationNumber.trim(),
                    phoneNumber: phoneNumberInput.trim(),
                  };
                  if (!candidatePhoneNumber) {
                    await dispatch(updatePhoneNumber(payload));
                  }
                  await dispatch(postUmeLogin(payload));
                }
              }}
            >
              {(props) => {
                const { values, touched, errors, handleChange } = props;

                return (
                  <Form>
                    <div
                      className="form-group"
                      style={{ marginBottom: "20px" }}
                    >
                      <label className="label">
                        JAMB Registration No (UTME/DE) *{" "}
                      </label>
                      <div
                        className={
                          errors.registrationNumber &&
                          touched.registrationNumber
                            ? "invalid-input-wrap"
                            : ""
                        }
                      >
                        <Field
                          value={values.registrationNumber}
                          id="registrationNumber"
                          name="registrationNumber"
                          type="text"
                          onChange={handleChange("registrationNumber")}
                          placeholder="Enter registration number"
                          className={`input-wrap ${
                            errors.registrationNumber &&
                            touched.registrationNumber
                              ? "is-invalid-input "
                              : !errors.registrationNumber &&
                                values.registrationNumber !== ""
                              ? "valid-input"
                              : ""
                          }`}
                        />
                      </div>
                      <ErrorMessage
                        name="registrationNumber"
                        className="form-input-error"
                        component="div"
                      />
                    </div>
                    {isEligible ? (
                      <div className="form-group">
                        <label className="label">Telephone No* </label>
                        <div
                          className={
                            errors.phoneNumber && touched.phoneNumber
                              ? "invalid-input-wrap phoneNumber_wrap"
                              : " phoneNumber_wrap"
                          }
                        >
                          <Field
                            value={values.phoneNumber}
                            id="phoneNumber"
                            name="phoneNumber"
                            type="tel"
                            onChange={handleChange("phoneNumber")}
                            placeholder="Enter phone number"
                            className={`input-wrap ${
                              errors.phoneNumber && touched.phoneNumber
                                ? "is-invalid-input "
                                : !errors.phoneNumber &&
                                  values.phoneNumber !== ""
                                ? "valid-input"
                                : ""
                            }`}
                          />
                        </div>
                        <ErrorMessage
                          name="phoneNumber"
                          className="form-input-error"
                          component="div"
                        />
                      </div>
                    ) : null}
                    {loginFailure?.length ? (
                      <AlertMsg type="error" message={loginFailure} />
                    ) : null}
                    <div className="submit_cta">
                      <button
                        disabled={loginPending}
                        className="btn shaded"
                        type="submit"
                      >
                        {!isEligible && !loginPending
                          ? "Check Eligibility"
                          : loginPending
                          ? "Please wait..."
                          : "Login"}
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
      {screenResolution?.width >= 767 && (
        <div className="login-footer">
          <p>
            {" "}
            <span>&copy; {yearTxt}</span> IBB University. All rights reserved
          </p>
          <p>
            Developed by{" "}
            <a
              href="https://gigbadi.com.ng/"
              target="_blank"
              rel="noreferrer"
              className="footer-link"
            >
              Gigbadi
            </a>
          </p>
        </div>
      )}

      {screenResolution?.width < 767 && (
        <div className="mobile-footer">
          <p>
            {" "}
            <span>&copy; {yearTxt}</span> IBB University. All rights reserved
          </p>
          <p>
            Developed by{" "}
            <Link
              to="https://gigbadi.com.ng/"
              target="_blank"
              className="footer-link"
            >
              Gigbadi
            </Link>
          </p>
        </div>
      )}
    </div>
  );
};

export default Login;
