import React from "react";
import { InAppTemplate } from "../../components/templates/portal";
import Documents from "./Documents";

const UploadDocument = () => {
  return (
    <InAppTemplate
      childComponent={<Documents />}
      pageTitle="Documents Upload"
    />
  );
};

export default UploadDocument;
