import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { InAppTemplate } from "../../components/templates/portal";
import { printAcknowledgmentSlip } from "../../redux/action/onboarding";
import PrintAcknowledgement from "./PrintAcknowledgement";
import { handleRequestErrors } from "../../shared/utils";

const Acknowlegdement = () => {
  const [downloadUrl, setDownloadUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { candidateInfo } = useSelector((state) => state.onboardingReducer);
  const { registrationNumber, phoneNumber } = candidateInfo || {};

  const handlePrint = async (payload) => {
    setLoading(true);
    await printAcknowledgmentSlip(payload)
      .then(({ data }) => {
        setDownloadUrl(data);
        setLoading(false);
      })
      .catch((err) => {
        if (err?.message && err?.message.includes("Network Error")) {
          setError("Please check your network and try again.");
        } else if (err?.response && err?.response.status < 500) {
          setError(err?.response?.data);
        } else {
          setError("An error occured, please try again later.");
        }
        setLoading(false);
        handleRequestErrors(err);
      });
  };

  useEffect(() => {
    if (registrationNumber && phoneNumber) {
      const payload = {
        registrationNumber,
        phoneNumber,
      };
      handlePrint(payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InAppTemplate
      childComponent={
        <PrintAcknowledgement
          url={downloadUrl}
          loading={loading}
          error={error}
        />
      }
      pageTitle="PRINT ACKNOWLEDGMENT"
    />
  );
};

export default Acknowlegdement;
