import React, { useState } from "react";
import "./index.scss";
import Done from "../../assets/Done.png";
import { Table } from "react-bootstrap";
import { postUmeLogin, uploadDocuments } from "../../redux/action/onboarding";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const Documents = () => {
  const [oLevelResult1, setOlevelResult1] = useState(null);
  const [oLevelResult2, setOlevelResult2] = useState(null);
  const [oLevelScratchCard1, setOlevelScratchCard1] = useState(null);
  const [oLevelScratchCard2, setOlevelScratchCard2] = useState(null);
  const [ninCard, setNinCard] = useState(null);
  const [aLevelResult, setALevelResult] = useState("");
  const [indigenizationLetter, setIndigenizationLetter] = useState(null);
  const [secondarySchoolTestimonial, setSecondarySchoolTestimonial] =
    useState(null);
  const [loading, setLoading] = useState(false);
  const [currentUploadIndex, setCurrentUploadIndex] = useState(false);
  const { candidateInfo } = useSelector((state) => state.onboardingReducer);
  const {
    registrationNumber,
    phoneNumber,
    hasUploadedIndigenizationLetter,
    hasUploadedNinCard,
    hasUploadedALevelResult,
    hasUploadedOLevelResult,
    hasUploadedOLevelScratchCard,
    hasUploadedSecondarySchoolTestimonial,
    oLevelResultCount,
    oLevelScratchCardCount,
    category,
  } = candidateInfo;
  const dispatch = useDispatch();
  const rows = [
    {
      label: "O Level Result (1) : (Required)",
      setState: setOlevelResult1,
      value: oLevelResult1,
      request: "UploadOLevelResult1",
      uploadDone: hasUploadedOLevelResult,
    },
    {
      label: "O Level Result Scratch Card (1) : (Required)",
      setState: setOlevelScratchCard1,
      value: oLevelScratchCard1,
      request: "UploadOLevelResultScratchCard1",
      uploadDone: hasUploadedOLevelScratchCard,
    },
    {
      label: "Indigenization Letter/Cert.: (Required)",
      setState: setIndigenizationLetter,
      value: indigenizationLetter,
      request: "UploadIndigenizationLetter",
      uploadDone: hasUploadedIndigenizationLetter,
    },
    {
      label: "Secondary School Testimonial : (Required)",
      setState: setSecondarySchoolTestimonial,
      value: secondarySchoolTestimonial,
      request: "UploadSecondarySchoolTestimonials",
      uploadDone: hasUploadedSecondarySchoolTestimonial,
    },
    {
      label: "ALevel Result : (Required)",
      setState: setALevelResult,
      value: aLevelResult,
      request: "UploadALevelResult",
      uploadDone: hasUploadedALevelResult,
    },
    {
      label: "NIN Card (NIMC) : (Required)",
      setState: setNinCard,
      value: ninCard,
      request: "UploadNinCards",
      uploadDone: hasUploadedNinCard,
    },
    {
      label: "O Level Result (2) : (Optional)",
      setState: setOlevelResult2,
      value: oLevelResult2,
      request: "UploadOLevelResult2",
      uploadDone: oLevelResultCount > 1,
    },
    {
      label: "O Level Result Scratch Card (2) : (Optional)",
      setState: setOlevelScratchCard2,
      value: oLevelScratchCard2,
      request: "UploadOLevelResultScratchCard2",
      uploadDone: oLevelScratchCardCount > 1,
    },
  ];

  const handleFileChange = (file, setValue) => {
    setValue(file);
  };

  const handleSubmit = async (file, type, index) => {
    if (file) {
      let fileSize = file.size / 1024;
      const allowedSize = type === "UploadALevelResult" ? 3000 : 200;
      if (fileSize > allowedSize) {
        return toast.error(
          `The maximum file size allowed is ${
            type === "UploadALevelResult" ? "3MB" : "200KB"
          }.`
        );
      }
      setLoading(true);
      setCurrentUploadIndex(index);

      const formData = new FormData();
      formData.append("file", file);
      formData.append("RegistrationNumber", registrationNumber);
      await uploadDocuments(formData, type)
        .then(() => {
          setLoading(false);
          toast.success("File uploaded successfully");
          dispatch(postUmeLogin({ registrationNumber, phoneNumber }, false));
        })
        .catch((err) => {
          setLoading(false);
          if (err?.message && err?.message.includes("Network Error")) {
            return toast.error("Please check your network and try again.");
          } else if (err?.response?.status < 500) {
            toast.error(err?.response?.data);
          } else {
            toast.error("An error occured");
          }
        });
    }
  };
  return (
    <div className="upload-docs-content">
      <p>
        IMPORTANT NOTE: Uploading a wrong document or a document in a wrong
        section could invalidate your submission.
      </p>
      <span>
        Specifications: JPG, JPEG formats ONLY, less than 200KB &
        of good quality.
      </span>
      <div className="table-wrapper">
        <Table bordered>
          <tbody>
            {rows.map((row, index) => {
              return row?.label.includes("ALevel Result") &&
                category !== "DE" ? null : (
                <tr key={index}>
                  <td>
                    {row.label}{" "}
                    {category === "DE" &&
                    row?.label.includes("ALevel Result") ? (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        Max size of 3MB
                      </span>
                    ) : null}
                    {row?.uploadDone ? (
                      <img
                        src={Done}
                        alt="Upload status"
                        style={{
                          width: "20px",
                          height: "20px",
                          marginLeft: "10px",
                        }}
                      />
                    ) : null}
                  </td>
                  <td className="upload-column">
                    <input
                      type="file"
                      accept="image/jpg, image/jpeg"
                      onChange={(e) =>
                        handleFileChange(
                          e.target.files[0],
                          rows[index].setState
                        )
                      }
                    />
                    <button
                      onClick={() =>
                        handleSubmit(row.value, row.request, index)
                      }
                    >
                      {loading && currentUploadIndex === index
                        ? "Submitting..."
                        : row?.uploadDone
                        ? "Resubmit"
                        : "Submit"}
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default Documents;
