import React, { useState } from "react";
import Instructions from "./Instructions";
import Payments from "../../assets/payments.png";
import "./index.scss";
import QuickLinks from "./QuickLinks";
import Header from "./Header";
import { Link } from "react-router-dom";

const LandingPage = () => {
  const [activeTab, setActiveTab] = useState("instructions");
  let yearTxt = new Date().getFullYear();
  return (
    <div className="landing-page-container">
      <div className="landing-page">
        <Header
          pageTitle="UTME/DE Result Submission Instructions / Guideline"
          showFirstSectionImage
        />
        <div className="second-section">
          <div className="tabs">
            <button
              onClick={() => setActiveTab("instructions")}
              className={activeTab === "instructions" ? "active" : "tab-button"}
            >
              <span
                className={
                  activeTab === "instructions"
                    ? "white-dot dot"
                    : "black-dot dot"
                  }
              ></span>{" "}
              WELCOME / INSTRUCTIONS
            </button>
            <button
              onClick={() => setActiveTab("links")}
              className={activeTab === "links" ? "active" : "tab-button"}
            >
              <div
                className={
                  activeTab === "links" ? "white-dot dot" : "black-dot dot"
                }
              ></div>
              QUICK LINKS
            </button>
          </div>
          {activeTab === "instructions" ? <Instructions /> : <QuickLinks />}
        </div>
        <div className="payment-img">
          <img src={Payments} alt="Payments" />
        </div>
        <div className="footer">
          <p> <span>&copy; {yearTxt}</span> IBB University. All rights reserved</p>
          <p>Developed by <Link to='https://gigbadi.com.ng/' target="_blank" className="footer-link">Gigbadi</Link></p>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
