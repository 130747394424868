let BASE_URL =
  window.location.hostname === "localhost" ||
  window.location.hostname ===
    "eduportal-staging-ibbu-postutme-webapp.azurewebsites.net"
    ? `https://eduportal-staging-ibbu-api.azurewebsites.net`
    : `https://eduportal-prod-ibbu-api.azurewebsites.net`;

export const POST_UME_URL = `${BASE_URL}/api/postume/student/v1/UndergraduatePostUme`;
export const UPLOAD_BASE_URL = `${BASE_URL}/api/upload/v1/UndergraduateFiles`;
export const apiKey =
  window.location.hostname === "localhost" ||
  window.location.hostname ===
    "eduportal-staging-ibbu-postutme-webapp.azurewebsites.net"
    ? "b3b4a4ef9c6c4f91b0e2c9b5c6713a95"
    : "b19a6d6e879b4f94b36d7b7d4b137f8d";

export const routes = {
  CANDIDATE_LOGIN: `${POST_UME_URL}/GetPostUtmeProfile`,
  ADD_OR_UPDATE_RESULT: `${POST_UME_URL}/AddOrUpdateOLevelResult`,
  POST_UTME_INVOICE: `${BASE_URL}/api/postume/student/v1/UndergraduatePostUmeFee/GetOrGenerateUndergraduatePostUmeFee`,
  PRINT_ACKNOWLEDGMENT_SLIP: `${POST_UME_URL}/PrintAcknowledgementSlip`,
  CHECK_ELIGIBILITY: `${POST_UME_URL}/Eligibility`,
  UPDATE_PHONE_NUMBER: `${POST_UME_URL}/UpdatePhoneNumber`,
  ADD_OR_UPDATE_ALEVEL_RESULT: `${POST_UME_URL}/AddOrUpdateALevelResult`,
};
