import React, { useEffect } from "react";
import { InAppTemplate } from "../../components/templates/portal";
import GenerateInvoice from "./GenerateInvoice";
import { generatePostUmeFee } from "../../redux/action/onboarding";
import { useDispatch, useSelector } from "react-redux";

const Invoice = () => {
  const dispatch = useDispatch();
  const { candidateInfo } = useSelector((state) => state.onboardingReducer);
  const { registrationNumber, phoneNumber } = candidateInfo || {};
  useEffect(() => {
    if (registrationNumber && phoneNumber) {
      const payload = {
        registrationNumber,
        phoneNumber,
      };
      dispatch(generatePostUmeFee(payload));
    }
  }, [candidateInfo]);
  return (
    <InAppTemplate
      childComponent={<GenerateInvoice />}
      pageTitle="POST UTME SCREENING PAYMENT"
    />
  );
};

export default Invoice;
