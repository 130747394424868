export const institutions = [
  "ABTI-American University of Nigeria",
  "Abia State Polytechnic",
  "Abia State University, Uturu",
  "Abubakar Tafawa Balewa University",
  "Adamawa State Polytechnic, Yola",
  "Adamawa State University, Mubi",
  "Adeniran Ogunsanya College of Education",
  "Adekunle Ajasin University",
  "Adeyemi College of Education, Ondo",
  "Ahmadu Bello University",
  "Ajayi Crowther University, Oyo",
  "Akanu Ibiam Federal Polytechnic, Unwana",
  "Akwa Ibom State Polytechnic",
  "Allover Central Polytechnic, Sango-Ota Ogun State",
  "Ambrose Alli University",
  "Anambra State University, Anambra",
  "Auchi Polytechnic, Auchi",
  "Bayero University, Kano",
  "Bells University",
  "Benson Idahosa University",
  "Bowen University, Iwo",
  "Cetep City University, Lagos",
  "College of Education, Afaha-Nsit",
  "College of Education, Agbor",
  "College of Education, Akwanga",
  "College of Education, Azare",
  "College of Education, Ekiadolor",
  "College of Education, Gindiri",
  "College of Education, Ikere Ekiti",
  "College of Education, Katsina Ala",
  "College of Education, Katsina-Ala",
  "College of Education, Minna",
  "College of Education, Oro, Kwara State",
  "College of Education, Warri",
  "College of Education, Zuba",
  "Covenant University, Ogun",
  "Crawford University, Oye Ekiti",
  "Cross River University of Technology",
  "Delta state Polytechnic, Ozoro",
  "Delta State University, Abraka",
  "Dorben Polytechnic (formerly Abuja School of Accountancy and Computer Studies)",
  "Ebonyi State University",
  "Enugu State University of Science & Technology",
  "FCT College of Education, Zuba",
  "Federal coll. of Animal health & production Tech., Ibadan",
  "Federal College of Education (Special), Oyo",
  "Federal College of Education (Technical) Bich",
  "Federal College of Education (Technical) Gusau",
  "Federal College of Education (Technical) Gombe",
  "Federal College of Education, Akoka",
  "Federal College of Education, Kano",
  "Federal College of Education, Kontagora",
  "Federal College of Education, Obudu",
  "Federal College of Education, Okene",
  "Federal College of Education, Omoku",
  "Federal College of Education, pankshin",
  "Federal College of Education, Yola",
  "Federal College of Education, Zaira",
  "Federal College of Education, Zaria",
  "Federal Polytechnic, Ado – Ekiti",
  "Federal Polytechnic, Bauchi",
  "Federal Polytechnic, Birnin-Kebbi",
  "Federal Polytechnic, Bida",
  "Federal Polytechnic, Damaturu",
  "Federal Polytechnic, Ede",
  "Federal Polytechnic, Idah",
  "Federal Polytechnic, Ilaro",
  "Federal Polytechnic, Mubi",
  "Federal Polytechnic, Nassarawa",
  "Federal Polytechnic, Nekede",
  "Federal Polytechnic, Oko",
  "Federal Polytechnic, Offa",
  "Federal University of Technology, Akure",
  "Federal University of Technology, Minna",
  "Federal University of Technology, Owerri",
  "Federal University of Technology, Yola",
  "Gateway Polytechnic Saapade",
  "Gombe state University, Tudun, Wada",
  "Hussaini Adamu Federal Polytechnic, kazaure",
  "Ibrahim Badamasi Babangida University, Lapai",
  "Igbinedion University, Okada",
  "Imo State University",
  "Institute of Management Technology, Enugu",
  "Joseph Ayo Babalola University",
  "Kaduna Polytechnic",
  "Kano State College of Education, Kano",
  "Kano State Polytechnic, Kano",
  "Katsina State University",
  "Kogi State University, Anyigba",
  "Kwara State College of Education",
  "Kwara State Polytechnic",
  "Ladoke Akintola University of Tech.",
  "Lagos City Computer College",
  "Lagos City Polytechnic",
  "Lagos State Polytechnic",
  "Lagos State University",
  "Lead City University",
  "Maritime Academy of Nigeria, Oron",
  "Michael Okpara University",
  "Moshood Abiola Polytechnic, Abeokuta",
  "National Open University of Nigeria",
  "National Teachers Institute, kaduna",
  "Nasarrawa State University",
  "NewGate College of Health Technology, Minna",
  "Niger State College of Agriculture, Mokwa",
  "Niger Delta University",
  "Niger State Polytechnic, Zungeru",
  "Nigerian Coll. of Aviation Tech., Zaira",
  "Nigerian Defence Academy",
  "Nnamdi Azikiwe University of Agriculture, Umudike",
  "Nuhu Bamalli Polytechnic, Zaria, Kaduna State",
  "Nwafor Orizu College of Education",
  "Obafemi Awolowo University, ile-ife",
  "Olabisi Onabanjo University, Ago-Iwoye",
  "Osun State College of Education, Ila-Orangun",
  "Osun State College of Education, Ilesa",
  "Osun State College of Technology, Esa-Oke",
  "Osun State Polytechnic, Iree",
  "Osun State University",
  "Oyo State College of Education, Oyo",
  "Pan African University, Lekki",
  "Plateau State University",
  "Redeemer’s University",
  "Renaissance University, Enugu",
  "Rivers State College of Education",
  "River State Polytechnic, Bori",
  "Rivers State University of Science and Technology",
  "Rufus Giwa Polytechnic, Owo",
  "Salem University, Lokoja",
  "Shaka Polytechnic",
  "St. Paul’s University College, Awka",
  "Tai Solarin University of Education, Ijebu-Ode",
  "The Polytechnic, Ibadan",
  "Theological College of Northern Nigeria,Bukuru",
  "University of Uyo",
  "University of Abuja",
  "University of Agriculture, Abeokuta",
  "University of Ado-Ekiti",
  "University of Benin",
  "University of Calabar",
  "University of Education, Ikere-Ekiti",
  "University of Ibadan",
  "University of Ilorin",
  "University of Jos",
  "University of Lagos",
  "University of Maiduguri",
  "University of Mkar, Mkar",
  "University of Nigeria",
  "University of port-harcourt",
  "University of Technology, Akwa-Ibom",
  "Usmanu Danfodiyo University, Sokoto",
  "Wesley University, Ondo",
  "Yaba College of Tech.",
];
