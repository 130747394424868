import React from "react";
import { NavLink } from "react-router-dom";
import User from "../../assets/user.png";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../redux/action/onboarding";
import { sideMenu } from "../../shared/_helpers/constants";

export const SideBarWrap = ({ pageProps }) => {
  const { candidateInfo } = useSelector((state) => state.onboardingReducer);
  const {
    registrationNumber,
    fullName,
    category,
    hasPaid,
    hasSubmitted,
    hasUploadedIndigenizationLetter,
    hasUploadedNinCard,
    hasUploadedOLevelResult,
    hasUploadedOLevelScratchCard,
    hasSubmittedALevelResult,
    hasUploadedALevelResult,
    hasUploadedSecondarySchoolTestimonial,
  } = candidateInfo || {};
  const dispatch = useDispatch();

  const MenuItems = () => {
    return (
      <div className="all-menu-items">
        <img
          src={User}
          alt="avatar"
          style={{ width: "140px", height: "140px" }}
        />
        <div className="candidate-info">
          <div className="info-item">
            <span>Name: </span>
            <span>{fullName}</span>
          </div>

          <div className="info-item">
            <span>JAMB Reg No: </span>
            <span>{registrationNumber}</span>
          </div>

          <div className="info-item">
            <span>Category: </span>
            <span>{category}</span>
          </div>
        </div>

        <div className="sidebar-links">
          {sideMenu.map((menu) => {
            return (
              <React.Fragment key={menu.name}>
                {((!hasPaid ||
                  !hasSubmitted ||
                  !hasUploadedIndigenizationLetter ||
                  !hasUploadedNinCard ||
                  !hasUploadedOLevelResult ||
                  !hasUploadedOLevelScratchCard ||
                  !hasUploadedSecondarySchoolTestimonial ||
                  (category === "DE" &&
                    (!hasSubmittedALevelResult || !hasUploadedALevelResult))) &&
                  menu.name === "Print Acknowledgment") ||
                (category !== "DE" &&
                  menu.name === `A'level Results`) ? null : (
                  <div
                    className={"link-wrapper"}
                    onClick={
                      menu?.name === "Logout"
                        ? () => dispatch(userLogout())
                        : null
                    }
                  >
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "active-nav" : ""
                      }
                      to={menu.routeTo}
                    >
                      <img
                        src={menu.icon}
                        alt="avatar"
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "7px",
                        }}
                      />
                      <span>{menu.name}</span>
                    </NavLink>
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div
      className={
        !pageProps?.isMobile ? "sidebar-wrap" : "sidebar-wrap withmobile"
      }
    >
      {pageProps?.isMobile && (
        <div
          className="sidebar-close"
          onClick={() => pageProps?.setShowVisible(false)}
        ></div>
      )}

      <MenuItems />
    </div>
  );
};

const SideBar = (pageProps, isMobile) => {
  return (
    <>
      {pageProps?.isMobile && <div className="underlay_mobile"></div>}
      <SideBarWrap pageProps={pageProps} isMobile={isMobile} />
    </>
  );
};

export default SideBar;
