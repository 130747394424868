import LoaderIcon from "../../assets/loading.gif";
import "./index.scss";
export const LoadingItem = () => {
  return (
    <div className="loader-wrap">
      <img src={LoaderIcon} alt="" />
      <div className="loading-txt">Please wait...</div>
    </div>
  );
};
