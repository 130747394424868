export const A_LEVEL_COURSE_LIST = [
  "ACCOUNTING TECHNOLOGY",
  "ACCOUNTING/ACCOUNTANCY",
  "ACTUARIAL SCIENCE",
  "ADULT AND COMMUNITY EDUCATION",
  "ADULT AND NON-FORMAL EDUCATION",
  "ADULT EDUCATION",
  "ADULT EDUCATION/ECONOMICS AND STATISTICS",
  "ADULT EDUCATION/ENGLISH LITERATURE",
  "ADULT EDUCATION/GEOGRAPHY AND REGIONAL PLANNING",
  "ADULT EDUCATION/POLITICAL SCIENCE AND PUBLIC ADMINISTRATION",
  "AFRICAN AND ASIAN STUDIES",
  "AGRICULTURAL AND BIO-RESOURCES ENGINEERING",
  "AGRICULTURAL AND ENVIRONMENTAL ENGINEERING",
  "AGRICULTURAL BUSINESS AND FINANCIAL MANAGEMENT",
  "AGRICULTURAL COOPERATIVE",
  "AGRICULTURAL ECONOMICS",
  "AGRICULTURAL ECONOMICS AND EXTENSION",
  "AGRICULTURAL ECONOMICS AND FARM MANAGEMENT",
  "AGRICULTURAL ECONOMICS AND MANAGEMENT STUDIES",
  "AGRICULTURAL EDUCATION",
  "AGRICULTURAL ENGINEERING",
  "AGRICULTURAL EXTENSION AND RURAL DEVELOPMENT",
  "AGRICULTURAL EXTENSION AND RURAL SOCIOLOGY",
  "AGRICULTURAL EXTENSION SERVICES",
  "AGRICULTURE",
  "AGRICULTURE ADMINISTRATION",
  "AGRICULTURE AND DEVELOPMENT EXTENSION",
  "AGRICULTURE AND FISHERIES MANAGEMENT",
  "AGRICULTURE METEOROLOGY AND WATER MANAGEMENT",
  "AGRONOMY",
  "ANATOMY",
  "ANIMAL AND ENVIRONMENTAL BIOLOGY",
  "ANIMAL BIOLOGY AND ENVIRONMENTAL",
  "ANIMAL BREEDING AND GENETICS",
  "ANIMAL PHYSIOLOGY",
  "ANIMAL PRODUCTION",
  "ANIMAL PRODUCTION AND HEALTH SERVICE",
  "ANIMAL PRODUCTION WITH NUTRITION",
  "ANIMAL SCIENCE",
  "ANIMAL SCIENCE AND PRODUCTION",
  "ANIMAL SCIENCE AND RANGE MANAGEMENT",
  "ANIMAL SCIENCE/NUTRITION",
  "ANTHROPOLOGY",
  "APPLIED BIOCHEMISTRY",
  "APPLIED BIOLOGY",
  "APPLIED BIOLOGY AND BIOCHEMISTRY",
  "APPLIED BIOLOGY AND BIOTECHNOLOGY",
  "APPLIED BOTANY",
  "APPLIED CHEMISTRY",
  "APPLIED ECOLOGY",
  "APPLIED GEOLOGY",
  "APPLIED GEOPHYSICS",
  "APPLIED MATHEMATICS AND STATISTICS",
  "APPLIED MICROBIOLOGY",
  "APPLIED MICROBIOLOGY AND BREWING",
  "APPLIED STATISTICS",
  "APPLIED ZOOLOGY",
  "AQUACULTURE AND FISHERIES MANAGEMENT",
  "ARABIC AND ISLAMIC STUDIES",
  "ARABIC LANGUAGE AND LITERATURE",
  "ARABIC STUDIES",
  "ARCHEOLOGY",
  "ARCHITECTURE",
  "ARTIFICIAL INTELLIGENCE",
  "ARTS (COMBINED)",
  "ARTS AND SOCIAL SCIENCE EDUCATION",
  "ARTS EDUCATION",
  "AUTOMOBIL TECHNOLOGY EDUCATION",
  "BANKING AND FINANCE",
  "BANKING, FINANCE AND INSURANCE",
  "BIO-INFORMATICS",
  "BIOCHEMISTRY",
  "BIODIVERSITY CONSERVATION AND UTILIZATION",
  "BIOLOGICAL SCIENCES",
  "BIOLOGY",
  "BIOLOGY EDUCATION",
  "BIOMEDICAL ENGINEERING",
  "BIOTECHNOLOGY",
  "BOTANY",
  "BOTANY AND MICROBIOLOGY",
  "BUILDING",
  "BUILDING EDUCATION",
  "BUILDING TECHNOLOGY",
  "BUSINESS ADMINISTRATION",
  "BUSINESS ECONOMICS",
  "BUSINESS MANAGEMENT",
  "CELL BIOLOGY AND GENETICS",
  "CHEMICAL AND INDUSTRIAL CHEMISTRY",
  "CHEMICAL ENGINEERING",
  "CHEMICAL SCIENCE",
  "CHEMICAL/PETROLEUM/PETROCHEMICAL ENGINEERING",
  "CHEMISTRY",
  "CHEMISTRY AND INDUSTRIAL CHEMISTRY",
  "CHRISTIAN RELIGIOUS KNOWLEDGE/STUDIES",
  "CHRISTIAN STUDIES",
  "CIVIL ENGINEERING",
  "CIVIL ENGINEERING AND HYDROLOGY",
  "CIVIL LAW",
  "CLASSICAL STUDIES",
  "COGNITIVE SCIENCE",
  "COMBINED PHYSICAL SCIENCES",
  "COMMON AND ISLAMIC LAW",
  "COMMON LAW",
  "COMMUNICATION AND MULTIMEDIA ADVERTISING",
  "COMMUNICATION AND MULTIMEDIA DESIGN",
  "COMMUNICATION AND MULTIMEDIA PRINTING JOURNALISM",
  "COMMUNICATION AND MULTIMEDIA TELEVISION/FILMING",
  "COMMUNICATION ARTS",
  "COMMUNICATION TECHNOLOGY",
  "COMPUTATIONAL ANALYSIS",
  "COMPUTER AND ECONOMICS",
  "COMPUTER AND INFORMATION SCIENCE",
  "COMPUTER AND INFORMATION TECHNOLOGY",
  "COMPUTER AND MATHEMATICS",
  "COMPUTER EDUCATION",
  "COMPUTER ENGINEERING",
  "COMPUTER SCIENCE",
  "COMPUTER SCIENCE AND ACCOUNTING",
  "COMPUTER SCIENCE AND ENGINEERING",
  "COMPUTER SCIENCE AND INFORMATION TECHNOLOGY",
  "COMPUTER SCIENCE WITH (ECONOMICS)(MATHEMATICS)",
  "COMPUTER SCIENCE WITH ACCOUNTING",
  "COMPUTER SCIENCE WITH ISLAMIC RELIGIOUS STUDIES",
  "COMPUTER WITH STATISTICS",
  "COOPERATIVE AND RURAL DEVELOPMENT",
  "COOPERATIVE ECONOMICS AND MANAGEMENT",
  "CROP PRODUCTION",
  "CROP PROTECTION",
  "CROP SCIENCE",
  "CROP SOIL AND ENVIRONMENTAL",
  "CURRICULUM AND INSTRUCTION",
  "CURRICULUM AND TEACHING",
  "CURRICULUM STUDIES",
  "DENTISTRY AND DENTAL TECHNOLOGY",
  "DRAMA/DRAMATIC ARTS/PERFORMING ARTS/THEATRE ARTS",
  "ECO-TOURISM AND WILDLIFE MANAGEMENT",
  "ECONOMICS",
  "EDUCATION",
  "EDUCATION ADMINISTRATION AND SUPERVISION",
  "EDUCATION AND COMPUTER SCIENCE",
  "EDUCATION AND MUSIC",
  "EDUCATION AND RELIGIOUS STUDIES",
  "EDUCATION AND YORUBA",
  "EDUCATION FOUNDATION AND ADMINISTRATION",
  "EDUCATION MANAGEMENT",
  "EDUCATION/ARABIC",
  "EDUCATION/ARTS",
  "EDUCATION/ARTS AND SOCIAL SCIENCE",
  "EDUCATION/CHEMISTRY",
  "EDUCATION/CHRISTIAN RELIGIOUS STUDIES",
  "EDUCATION/EDO LANGUAGE",
  "EDUCATION/ENGLISH LANGUAGE",
  "EDUCATION/FINE AND APPLIED ARTS",
  "EDUCATION/FRENCH",
  "EDUCATION/GEOGRAPHY",
  "EDUCATION/HAUSA",
  "EDUCATION/HISTORY",
  "EDUCATION/IGBO",
  "EDUCATION/IGBO/LINGUISTICS",
  "EDUCATION/ISLAMIC STUDIES",
  "EDUCATION/LANGUAGE AND FRENCH",
  "EDUCATION/LANGUAGE ARTS",
  "EDUCATION/LANGUAGE/ENGLISH",
  "EDUCATION/LINGUISTICS",
  "EDUCATION/MATHEMATICS",
  "EDUCATION/SCIENCE",
  "EDUCATION/TECHNOLOGY/INTRODUCTORY EDUCATION",
  "EDUCATIONAL ADMINISTRATION",
  "EDUCATIONAL FOUNDATION",
  "EDUCATIONAL FOUNDATION AND MANAGEMENT",
  "EDUCATIONAL PSYCHOLOGY,GUIDANCE AND COUNSELLING",
  "EFIK-IBIBIO",
  "ELECTRICAL ENGINEERING",
  "ELECTRICAL/ELECTRONICS ENGINEERING",
  "ELECTRICAL/ELECTRONICS TECHNOLOGY",
  "ELECTRICAL/ELECTRONICS/COMPUTER ENGINEERING",
  "ELECTRONICS",
  "ELECTRONICS AND COMPUTER TECHNOLOGY",
  "ELECTRONICS ENGINEERING",
  "ELEMENTARY EDUCATION",
  "ENERGY AND PETROLEUM STUDIES",
  "ENERGY STUDIES",
  "ENGINEERING PHYSICS",
  "ENGLISH AND LITERARY STUDIES",
  "ENGLISH LANGUAGE",
  "ENGLISH LANGUAGE AND LITERATURE",
  "ENGLISH STUDIES",
  "ENGLISH AND INTERNATIONAL STUDIES",
  "ENTREPRENEURSHIP",
  "ENVIRONMENTAL BIOLOGY",
  "ENVIRONMENTAL BIOLOGY AND FISHERY",
  "ENVIRONMENTAL EDUCATION",
  "ENVIRONMENTAL ENGINEERING",
  "ENVIRONMENTAL MANAGEMENT",
  "ENVIRONMENTAL MANAGEMENT AND TECHNOLOGY",
  "ENVIRONMENTAL MANAGEMENT TECHNOLOGY",
  "ENVIRONMENTAL TECHNOLOGY",
  "ESTATE MANAGEMENT",
  "EXERCISE AND SPORT SCIENCE",
  "FAMILY NUTRITION AND CONSUMER SCIENCES",
  "FARM MANAGEMENT AND AGRIC. EXTENSION",
  "FILM AND VIDEO STUDIES",
  "FINE AND APPLIED ARTS",
  "FINE ARTS",
  "FISHERIES",
  "FISHERIES MANAGEMENT",
  "FISHERIES AND AQUACULTURE",
  "FISHERIES AND AQUATIC BIOLOGY",
  "FISHERIES AND WILDLIFE MANAGEMENT",
  "FORENSIC AND TECHNOLOGY SCIENCE",
  "FORENSIC SCIENCE",
  "FORESTRY",
  "FORESTRY AND WILDLIFE MANAGEMENT",
  "FRENCH",
  "FRENCH AND GERMAN",
  "FRENCH AND INTERNATIONAL STUDIES",
  "FRENCH AND LINGUISTICS",
  "FRENCH AND SPANISH",
  "FRENCH EDUCATION",
  "GENERAL LINGUISTICS",
  "GEODETIC ENGINEERING",
  "GEOGRAPHY",
  "GEOGRAPHY AND ENVIRONMENTAL MANAGEMENT",
  "GEOGRAPHY AND ENVIRONMENTAL STUDIES",
  "GEOGRAPHY AND METEOROLOGY",
  "GEOGRAPHY/PHYSICS",
  "GEOLOGY",
  "GEOLOGY AND MINING",
  "GEOPHYSICS",
  "GERMAN",
  "GERMAN AND INTERNATIONAL STUDIES",
  "GERMAN AND LINGUISTICS",
  "GERMAN WITH ARABIC",
  "GERMAN WITH COMPUTER SCIENCE",
  "GOVERNMENT AND PUBLIC ADMINISTRATION",
  "GUIDANCE AND COUNSELLING",
  "HAUSA",
  "HAUSA AND LINGUISTICS",
  "HAUSA LANGUAGE",
  "HAUSA, LINGUISTICS AND LITERATURE",
  "HEALTH EDUCATION",
  "HISTORY",
  "HISTORY AND ARCHEOLOGY",
  "HISTORY AND DIPLOMATIC STUDIES",
  "HISTORY AND INTERNATIONAL STUDIES",
  "HISTORY AND POLITICAL SCIENCE",
  "HOME ECONOMICS",
  "HOME SCIENCE",
  "HOME SCIENCE AND MANAGEMENT",
  "HOSPITALITY MANAGEMENT AND TOURISM",
  "HOSPITALITY AND TOURISM MANAGEMENT",
  "HUMAN ANATOMY",
  "HUMAN KINETICS",
  "HUMAN KINETICS AND HEALTH EDUCATION",
  "HUMAN NUTRITION AND DIETETICS",
  "HUMAN NUTRITION AND HOME ECONOMICS",
  "HUMAN PHYSIOLOGY",
  "IBIBIO LANGUAGE",
  "IGBO",
  "INDUSTRIAL CHEMISTRY",
  "INDUSTRIAL DESIGN",
  "INDUSTRIAL EDUCATION TECHNOLOGY",
  "INDUSTRIAL MATHEMATICS",
  "INDUSTRIAL MICROBIOLOGY",
  "INDUSTRIAL PHYSICS",
  "INDUSTRIAL RELATIONS AND PERSONNEL MANAGEMENT",
  "INDUSTRIAL RELATIONS AND PERSONNEL MANAGEMENT TECHNOLOGY",
  "INDUSTRIAL RELATIONS AND PUBLIC ADMINISTRATION",
  "INDUSTRIAL RELATIONS AND PUBLIC MANAGEMENT",
  "INDUSTRIAL TECHNOLOGY EDUCATION",
  "INDUSTRIAL WELDING AND FABRICATION TECHNOLOGY",
  "INFORMATION AND COMMUNICATION ENGINEERING",
  "INFORMATION AND COMMUNICATION TECHNOLOGY",
  "INFORMATION AND MEDIA TECHNOLOGY",
  "INFORMATION MANAGEMENT TECHNOLOGY",
  "INFORMATION RESOURCE MANAGEMENT",
  "INFORMATION SCIENCE",
  "INFORMATION STUDIES",
  "INFORMATION SYSTEM",
  "INFORMATION SYSTEM AND MANAGEMENT",
  "INFORMATION TECHNOLOGY",
  "INSURANCE",
  "INSURANCE AND RISK MANAGEMENT",
  "INSURANCE AND VALUATION",
  "INTERNATIONAL LAW",
  "INTERNATIONAL RELATIONS",
  "INTERNATIONAL STUDIES",
  "INTERNATIONAL STUDIES AND DIPLOMACY",
  "INTERNATIONAL TOURISM MANAGEMENT",
  "INTERNATIONAL TOURISM AND HOSPITALITY MANAGEMENT",
  "ISLAMIC AND ARABIC STUDIES",
  "ISLAMIC STUDIES",
  "ISLAMIC STUDIES/EDUCATION",
  "ITALIAN LANGUAGE",
  "JAPANESE",
  "JAPANESE STUDIES",
  "JOURNALISM",
  "JOURNALISM AND MASS COMMUNICATION",
  "JOURNALISM AND MEDIA STUDIES",
  "KANNADA",
  "KANNADA AND LINGUISTICS",
  "KANNADA AND LITERATURE",
  "LANGUAGE AND COMMUNICATION STUDIES",
  "LANGUAGE ARTS",
  "LANGUAGE STUDIES",
  "LAW",
  "LINGUISTICS",
  "LINGUISTICS AND AFRICAN LANGUAGES",
  "LINGUISTICS AND LITERARY STUDIES",
  "LINGUISTICS AND NIGERIAN LANGUAGES",
  "LINGUISTICS AND NIGERIAN LANGUAGE/HAUSA",
  "LINGUISTICS AND PHONETICS",
  "LINGUISTICS AND TRANSLATION STUDIES",
  "LINGUISTICS AND URHOBO",
  "LINGUISTICS/HAUSA",
  "LINGUISTICS/IGBO",
  "LINGUISTICS/IGBO AND NIGERIAN LANGUAGES",
  "LINGUISTICS/ISLAMIC STUDIES",
  "LINGUISTICS/YORUBA",
  "LITERARY STUDIES",
  "LITERATURE IN ENGLISH",
  "LOCAL GOVERNMENT ADMINISTRATION",
  "LOCAL GOVERNMENT AND DEVELOPMENT STUDIES",
  "LOCAL GOVERNMENT STUDIES",
  "MARINE BIOLOGY",
  "MARKETING",
  "MARKETING MANAGEMENT",
  "MASS COMMUNICATION",
  "MATERIAL SCIENCE AND ENGINEERING",
  "MATHEMATICAL SCIENCES",
  "MATHEMATICS",
  "MATHEMATICS AND STATISTICS",
  "MATHEMATICS EDUCATION",
  "MATHEMATICS WITH ECONOMICS",
  "MATHEMATICS WITH STATISTICS",
  "MATHEMATICS WITH TECHNOLOGY",
  "MECHANICAL ENGINEERING",
  "MECHANICAL/PRODUCTION ENGINEERING",
  "MECHATRONICS",
  "MEDICAL BIOCHEMISTRY",
  "MEDICAL LABORATORY SCIENCE",
  "MEDICAL LABORATORY TECHNOLOGY",
  "MEDICAL MICROBIOLOGY",
  "MEDICINE AND SURGERY",
  "METALLURGICAL AND MATERIALS ENGINEERING",
  "METEOROLOGY",
  "MICROBIOLOGY",
  "MINING ENGINEERING",
  "MINING GEOLOGY",
  "MUSIC",
  "NAUTICAL SCIENCE",
  "NURSING",
  "NURSING/NURSING SCIENCE",
  "NUTRITION",
  "NUTRITION AND DIETETICS",
  "NUTRITION SCIENCE",
  "OPERATIONS RESEARCH",
  "OPTOMETRY",
  "PAINTING",
  "PEACE AND CONFLICT STUDIES",
  "PERFORMING ARTS",
  "PETROCHEMICAL ENGINEERING",
  "PETROLEUM AND GAS ENGINEERING",
  "PETROLEUM ENGINEERING",
  "PETROLEUM GEOSCIENCE",
  "PHARMACEUTICAL SCIENCES",
  "PHARMACY",
  "PHILOSOPHY",
  "PHYSICAL EDUCATION",
  "PHYSICAL EDUCATION AND SPORTS",
  "PHYSICAL SCIENCES",
  "PHYSICS",
  "PHYSICS AND APPLIED PHYSICS",
  "PHYSICS AND COMPUTER SCIENCE",
  "PHYSICS AND ELECTRONICS",
  "PHYSICS EDUCATION",
  "PHYSIOLOGY",
  "PHYSIOTHERAPY",
  "PLANT BIOLOGY",
  "PLANT BIOLOGY AND BIOTECHNOLOGY",
  "PLANT BREEDING AND SEED TECHNOLOGY",
  "PLANT SCIENCE",
  "PLANT SCIENCE AND BIOTECHNOLOGY",
  "PLANT SCIENCE AND CROP PRODUCTION",
  "PLANT SCIENCE/BOTANY",
  "POLITICAL SCIENCE",
  "POLITICAL SCIENCE AND INTERNATIONAL RELATIONS",
  "POPULATION STUDIES",
  "POSTHARVEST TECHNOLOGY",
  "PRIMARY EDUCATION STUDIES",
  "PRIVATE LAW",
  "PRODUCTION AND INDUSTRIAL ENGINEERING",
  "PROJECT MANAGEMENT TECHNOLOGY",
  "PSYCHOLOGY",
  "PUBLIC ADMINISTRATION",
  "PUBLIC HEALTH",
  "PUBLIC LAW",
  "QUANTITY SURVEYING",
  "RADIOGRAPHY",
  "RADIOGRAPHY AND RADIOLOGICAL SCIENCE",
  "RELIGIOUS AND CULTURAL STUDIES",
  "RELIGIOUS STUDIES",
  "RELIGIOUS STUDIES/CHRISTIAN RELIGIOUS STUDIES",
  "RELIGIOUS STUDIES/ISLAMIC STUDIES",
  "RENEWABLE ENERGY TECHNOLOGY",
  "RISK MANAGEMENT AND INSURANCE",
  "RISK MANAGEMENT/INSURANCE",
  "RURAL AND COMMUNITY DEVELOPMENT",
  "RUSSIAN",
  "SCIENCE AND COMPUTER EDUCATION",
  "SCIENCE EDUCATION",
  "SECONDARY EDUCATION",
  "SECONDARY EDUCATION STUDIES",
  "SECRETARIAL ADMINISTRATION",
  "SECRETARIAL EDUCATION",
  "SECRETARIAL STUDIES",
  "SOCIAL STUDIES",
  "SOCIOLOGY",
  "SOIL SCIENCE",
  "SOIL SCIENCE AND LAND RESOURCE MANAGEMENT",
  "SOIL SCIENCE AND LAND USE MANAGEMENT",
  "SOIL SCIENCE MANAGEMENT",
  "SOIL SCIENCE/TECHNOLOGY",
  "SPECIAL EDUCATION",
  "SPORT SCIENCE",
  "SPORT SCIENCE AND HEALTH EDUCATION",
  "SPORT SCIENCE AND RECREATION MANAGEMENT",
  "SPORT SCIENCE AND TECHNOLOGY",
  "STATISTICS",
  "STATISTICS AND DEMOGRAPHY",
  "STATISTICS AND INFORMATICS",
  "STATISTICS/COMPUTER SCIENCE",
  "SURVEYING AND GEOINFORMATICS",
  "SURVEYING AND GEOINFORMATION",
  "SURVEYING AND GEOPHYSICS",
  "SURVEYING AND MAPPING",
  "SURVEYING AND PHOTOGRAMMETRY",
  "SURVEYING AND REMOTE SENSING",
  "SURVEYING ENGINEERING",
  "TEACHER EDUCATION SCIENCE",
  "TECHNOLOGY AND VOCATIONAL EDUCATION",
  "THEATRE ARTS",
  "TOURISM",
  "TOURISM AND HOSPITALITY MANAGEMENT",
  "TRANSPORT AND PLANNING",
  "TRANSPORT AND TOURISM MANAGEMENT",
  "TRANSPORT MANAGEMENT",
  "URBAN AND REGIONAL PLANNING",
  "URDU",
  "URHOBO",
  "VETERINARY MEDICINE",
  "VETERINARY MICROBIOLOGY",
  "VETERINARY SCIENCE",
  "VISUAL ARTS",
  "WATER ENGINEERING",
  "WATER RESOURCES AND ENVIRONMENTAL ENGINEERING",
  "WATER RESOURCES MANAGEMENT",
  "WELDING AND FABRICATION TECHNOLOGY",
  "WOOD PRODUCT ENGINEERING",
  "WOODWORK TECHNOLOGY",
  "YORUBA",
  "ZOOLOGY",
  "ZOOLOGY AND ANIMAL SYSTEMATICS",
  "ZOOLOGY AND AQUACULTURE",
];
