import React from "react";

const Instructions = () => {
  const loginLink =
    window.location.hostname === "localhost"
      ? "http://localhost:3000/login"
      : window.location.hostname ===
        "eduportal-staging-ibbu-postutme-webapp.azurewebsites.net"
      ? "https://eduportal-staging-ibbu-postutme-webapp.azurewebsites.net/login"
      : "https://postutme.ibbu.edu.ng/login";

  return (
    <div className="main-content">
      <p>
        Welcome to the Post UTME/DE Online O'Level Result Screening Portal of
        IBRAHIM BADAMASI BABANGIDA UNIVERSITY, Lapai, Niger State. <br />
        All prospective candidates should take note of the following before and
        during the submission of their O'level result(s).
      </p>
      <h4>
        <a href={loginLink} target="_blank" rel="noreferrer">
          CLICK HERE TO LOGIN TO THE POST UTME SCREENING PORTAL
        </a>
      </h4>
      <p>
        All complains and suggestion should be directed to the I.C.T Unit,
        Academic office Or you can send an email to postutme@ibbu.edu.ng.
        <br /> You can also call the help line on 08077034546 between 10:00AM
        and 3:00PM Mondays-Fridays.
      </p>
      <p className="sub-heading">
        The following are the steps for the result submission/screening process:
      </p>
      <div className="processes">
        <p>
          <strong>Note 1.</strong> All UTME/DE prospective candidates are to
          check/verify their eligibility for the Ibrahim Badamasi Babangida
          University UTME/DE O'Level results screening/verification exercise.
        </p>
        <p>
          <strong>Note 2.</strong> Successful prospective candidates who have met the minimum requirements should proceed to payment by generating an invoice and then follow the payment instructions.
        </p>
        <p>
          <strong>Note 3.</strong> The prospective candidate is required to make
          a payment of; TWO THOUSAND NAIRA ONLY (N2,000 : 00K) - to submit
          either ONE(1) or TWO(2) O'LEVEL RESULT(s). The amount includes all
          transaction charges.
        </p>
      </div>
      <div className="steps">
        <h5>Stage 1: CANDIDATES ELIGIBILITY CHECK:</h5>
        <ul>
          <li>
            Enter their JAMB Registration Number to confirm if they are
            eligible. If eligible:
          </li>
          <li>
            Enter their mobile phone number to proceed.
          </li>
          <li>
            After login , then click on the Payment menu to generate invoice and make payment.
          </li>
        </ul>

        <h5>Stage 2: RESULT SUBMISSION:</h5>
        <p> You are required to log into the Portal using your UTME Registration Number and Phone Number. <br/>
           Candidates are advised to correctly input their results and any other requested information on the portal. After submission, candidates may print out their submission acknowledgment slip for personal reference only. </p>
      </div>
    </div>
  );
};

export default Instructions;
