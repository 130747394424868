import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import AppRoutes from "./routes";
import { history } from "./shared/_helpers/history";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
function App() {
  return (
    <HistoryRouter history={history}>
      <ToastContainer
        position="top-right"
        autoClose={8000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: "20px" }}
      />
      <AppRoutes />
    </HistoryRouter>
  );
}

export default App;
