import Dropdown from "react-bootstrap/Dropdown";
import Logo from "../../assets/logo2.png";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { Logout } from "../../redux/action/onboarding";
import useWindowSize from "../../hooks/useWindowSize";

export const AppHeader = () => {
  const { candidateInfo } = useSelector((state) => state.onboardingReducer);
  const {
    hasPaid,
    hasSubmitted,
    hasUploadedIndigenizationLetter,
    hasUploadedNinCard,
    hasUploadedOLevelResult,
    hasUploadedOLevelScratchCard,
    hasUploadedSecondarySchoolTestimonial,
    hasUploadedALevelResult,
    HasSubmittedALevelResult,
    category,
  } = candidateInfo || {};

  const dispatch = useDispatch();
  const screenResolution = useWindowSize();
  return (
    <div className="ad_portal_header">
      <div className="left_wrap">
        <div className="logo-wrap">
          <a href="https://ibbu.edu.ng/" target="_blank" rel="noreferrer">
            <img src={Logo} alt="School Logo" />
          </a>
        </div>
        <div className="head_txt_wrap">
          <div className="portal_mode">Post UTME Screening Portal</div>
          <div className="school_name_txt">
            Ibrahim Badamasi Babangida University
          </div>
        </div>
      </div>
      {screenResolution?.width <= 600 ? (
        <div className="user_info_wrap">
          {candidateInfo && (
            <Dropdown className="report_dropdown">
              <Dropdown.Toggle id="dropdown-basic" className="action-cta">
                <div className="profile_drop">
                  {candidateInfo?.fullName?.split(" ")[0]}
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href={`/info`}> UTME Data</Dropdown.Item>
                <Dropdown.Item href="/results">O'level Results</Dropdown.Item>
                {category === "DE" ? (
                  <Dropdown.Item href="/alevelresults">
                    A'level Results
                  </Dropdown.Item>
                ) : null}
                <Dropdown.Item href={`/uploads`}>
                  Documents upload
                </Dropdown.Item>
                <Dropdown.Item href={`/payment`}>Payment</Dropdown.Item>
                {hasPaid &&
                hasSubmitted &&
                hasUploadedIndigenizationLetter &&
                hasUploadedNinCard &&
                hasUploadedOLevelResult &&
                hasUploadedOLevelScratchCard &&
                hasUploadedSecondarySchoolTestimonial &&
                (category === "DE"
                  ? hasUploadedALevelResult && HasSubmittedALevelResult
                  : true) ? (
                  <Dropdown.Item href={`/acknowledgment`}>
                    Print Acknowledgment
                  </Dropdown.Item>
                ) : null}
                <Dropdown.Item onClick={() => dispatch(Logout())}>
                  {" "}
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      ) : null}
    </div>
  );
};
