/* eslint-disable no-useless-escape */
// const clearNumber = (value = "") => {
//   return value.replace(/\D+/g, "");
// };

export const handleRequestErrors = (error) => {
  if (error !== undefined && error !== null && error !== "") {
    if (typeof error.response === "object" && error?.response?.data) {
      if (error.response && error.response?.data?.title) {
        return error.response.data?.title;
      } else {
        if (error.response && error.response?.data) {
          return error.response.data;
        }
      }

      if (error.response && error.response?.data?.error_detail) {
        return error.response.data.error_detail;
      } else {
        if (error.response?.data?.detail) {
          return error.response?.data?.detail;
        } else if (error.response?.data?.error) {
          return error.response.data.error;
        } else {
          return "The service is presently unreachable. Please try again later";
        }
      }
    }

    if (
      error.toString() === "Error: Network Error" ||
      error.toString() === "AxiosError: Network Error"
    ) {
      return "Please check your network and try again";
    }
    return "An error occured";
  }

  return "Something went wrong. Please try again";
};

export const allowNumbersOnly = (numbers, maxLength) => {
  // console.log("numbers", numbers)
  if (numbers !== undefined && numbers !== null) {
    let filteredNum = numbers.replace(/\D/g, "");

    if (typeof maxLength === "number") {
      if (filteredNum.toString().length <= maxLength) {
        return filteredNum;
      } else {
        return filteredNum.toString().substring(0, maxLength);
      }
    } else {
      return filteredNum;
    }
  } else {
    return null;
  }
};

export const specialCharactersRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
