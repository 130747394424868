import React from "react";
import Done from "../../assets/Done.png";
import Cancel from "../../assets/Cancel.png";
import "./index.scss";
import { useSelector } from "react-redux";
const DocsUploaded = () => {
  const { candidateInfo } = useSelector((state) => state.onboardingReducer);
  const {
    hasUploadedIndigenizationLetter,
    hasUploadedNinCard,
    hasUploadedOLevelResult,
    hasUploadedOLevelScratchCard,
    hasUploadedALevelResult,
    hasUploadedSecondarySchoolTestimonial,
    oLevelResultCount,
    oLevelScratchCardCount,
    category,
  } = candidateInfo || {};
  return (
    <div className="docs-uploaded">
      <ul>
        <li>
          <span>O Level Result[{oLevelResultCount || 0}]</span>
          <img
            src={hasUploadedOLevelResult ? Done : Cancel}
            alt="done"
            style={{ width: "25px", height: "20px", marginLeft: "10px" }}
          />
        </li>
        <li>
          <span>Result Scratch Card[{oLevelScratchCardCount || 0}]</span>
          <img
            src={hasUploadedOLevelScratchCard ? Done : Cancel}
            alt="done"
            style={{ width: "25px", height: "20px", marginLeft: "10px" }}
          />
        </li>
        {category === "DE" ? (
          <li>
            <span>A'Level Result</span>
            <img
              src={hasUploadedALevelResult ? Done : Cancel}
              alt="Cancel"
              style={{ width: "25px", height: "20px", marginLeft: "10px" }}
            />
          </li>
        ) : null}
        <li>
          <span>Indigenization Certificate</span>
          <img
            src={hasUploadedIndigenizationLetter ? Done : Cancel}
            alt="Cancel"
            style={{ width: "25px", height: "20px", marginLeft: "10px" }}
          />
        </li>
        <li>
          <span>Sec. Sch Testimonial</span>
          <img
            src={hasUploadedSecondarySchoolTestimonial ? Done : Cancel}
            alt="done"
            style={{ width: "25px", height: "20px", marginLeft: "10px" }}
          />
        </li>
        <li>
          <span>NIN Card(NIMC)</span>
          <img
            src={hasUploadedNinCard ? Done : Cancel}
            alt="Cancel"
            style={{ width: "25px", height: "20px", marginLeft: "10px" }}
          />
        </li>
      </ul>
    </div>
  );
};

export default DocsUploaded;
