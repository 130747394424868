import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import CandidateInfo from "../modules/CandidateInfo";
import UploadDocument from "../modules/DocumentUpload";
import OlevelResults from "../modules/OlevelResults";
import "react-toastify/dist/ReactToastify.css";
import Login from "../modules/Login";
import LandingPage from "../components/LandingPage";
import FAQs from "../components/FAQs";
import Invoice from "../modules/Invoice";
import Acknowlegdement from "../modules/Acknowledgment";
import ALevelResults from "../modules/ALevelResult";

const ProtectedRoute = ({ isAllowed, redirectPath = "/login", children }) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};
const AppRoutes = () => {
  const { candidateInfo } = useSelector((state) => state.onboardingReducer);
  const {
    hasPaid,
    hasSubmitted,
    hasUploadedIndigenizationLetter,
    hasUploadedNinCard,
    hasUploadedOLevelResult,
    hasUploadedOLevelScratchCard,
    hasUploadedSecondarySchoolTestimonial,
    hasUploadedALevelResult,
    hasSubmittedALevelResult,
    category,
  } = candidateInfo || {};
  const { registrationNumber } = candidateInfo || {};
  return (
    <Routes>
      <Route path="/" exact element={<LandingPage />} />
      <Route path="/faq" element={<FAQs />} />
      <Route
        path="/login"
        exact
        element={
          !!registrationNumber ? <Navigate to="/info" replace /> : <Login />
        }
      />

      <Route
        path="/info"
        element={
          <ProtectedRoute isAllowed={!!registrationNumber}>
            <CandidateInfo />
          </ProtectedRoute>
        }
      />
      <Route
        path="/uploads"
        element={
          <ProtectedRoute isAllowed={!!registrationNumber}>
            <UploadDocument />
          </ProtectedRoute>
        }
      />
      <Route
        path="/results"
        element={
          <ProtectedRoute isAllowed={!!registrationNumber}>
            <OlevelResults />
          </ProtectedRoute>
        }
      />
      <Route
        path="/payment"
        element={
          <ProtectedRoute isAllowed={!!registrationNumber}>
            <Invoice />
          </ProtectedRoute>
        }
      />
      <Route
        path="/alevelresults"
        element={
          <ProtectedRoute isAllowed={!!registrationNumber && category === "DE"}>
            <ALevelResults />
          </ProtectedRoute>
        }
      />
      <Route
        path="/acknowledgment"
        element={
          <ProtectedRoute
            isAllowed={
              !!registrationNumber &&
              hasPaid &&
              hasSubmitted &&
              hasUploadedIndigenizationLetter &&
              hasUploadedNinCard &&
              hasUploadedOLevelResult &&
              hasUploadedOLevelScratchCard &&
              hasUploadedSecondarySchoolTestimonial &&
              (category === "DE"
                ? hasUploadedALevelResult && hasSubmittedALevelResult
                : true)
            }
          >
            <Acknowlegdement />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
