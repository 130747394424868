import { FaSortDown } from "react-icons/fa";
import Select, { components } from "react-select";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaSortDown style={{ color: "#000000" }} />
    </components.DropdownIndicator>
  );
};

const DropDown = ({
  field,
  options,
  label,
  value,
  disabled = false,
  defaultValue,
  handleChange,
  className = "",
  placeholder,
  borderColor,
}) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      height: "40px",
      display: "flex",
      alignItems: "center",
      background: "#FFFFFF",
      border: state.menuIsOpen
        ? `1px solid ${borderColor || "#FFFFFF"}`
        : `1px solid ${borderColor || "#FFFFFF"}`,
      boxShadow: state.isFocused ? "0 0 0 1px #FFFFFF" : "#FFFFFF",
      ":hover": {
        borderColor: borderColor || "#FFFFFF",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      marginRight: "2px",
    }),
    focus: () => ({
      borderColor: "#F5F5F5",
      boxShadow: "0 0 0 1px #FFFFFF",
    }),
    menu: (provided) => ({
      ...provided,
      width: "100%",
      backgroundColor: "white",
      borderRadius: "5px",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: "white",
      color: "black",
      ":hover": {
        backgroundColor: "#00A85B",
        borderColor: borderColor || "#FFFFFF",
        color: "#F1FFF9",
      },
    }),
  };
  const onChange = (selectedOption) => {
    handleChange(
      field === "grade" || field === "examMonth"
        ? selectedOption
        : selectedOption.value
    );
  };

  return (
    <div className={`form-group form-dropdown ${className}`}>
      <Select
        onChange={onChange}
        id={field}
        name={field}
        isDisabled={disabled}
        options={options}
        value={value}
        defaultValue={defaultValue}
        styles={customStyles}
        components={{ DropdownIndicator }}
        placeholder={placeholder}
      />
    </div>
  );
};

export default DropDown;
