// import { dispatch } from "rxjs/internal/observable/pairs";
import { Dispatch } from "redux";
import { Logout } from "../redux/action/onboarding";
// import { ApiRequestType } from "../types";
// import { routes } from "./paths";
const axios = require("axios");

let cancelToken;
// let loggedAccountTenant = localStorage.getItem("stuAuthDataTenant") || "{}"

const instance = axios.create({
  validateStatus: function (status) {
    return status >= 200 && status < 210;
  },
});

const setTokenAuthorization = (url) => {
  let loggedAccountInfo = localStorage.getItem("stuXAuth") || "{}",
    serviceToTest = url.split("api/")[1];
  let urlsWithoutAuthentication = [
      "postume/student/v1/UndergraduatePostUme/GetPostUtmeProfile",
    ],
    binaryUploadUrls = ["/UndergraduateFiles/UploadUndergraduatePassport"];
  if (Object.keys(loggedAccountInfo).length === 0) {
    // if(instance.defaults.headers.common?.Authorization){
    delete instance.defaults.headers.common.Authorization;
    // }
  }

  instance.defaults.headers.common["Content-Type"] = "application/json";

  if (Object.keys(loggedAccountInfo).length >= 1) {
    let user = JSON.parse(loggedAccountInfo);

    // console.log("serviceToTest", serviceToTest)
    // console.log("urlsWithoutAuthentication", urlsWithoutAuthentication.indexOf(serviceToTest))

    if (urlsWithoutAuthentication.indexOf(serviceToTest) === -1) {
      instance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${user.access_token}`;
    }

    instance.defaults.headers.common["Accept"] = "application/json";
  }

  if (binaryUploadUrls.indexOf(serviceToTest) === -1) {
    instance.defaults.headers.common["Content-Type"] = "application/json";
  }
  if (binaryUploadUrls.indexOf(serviceToTest) > -1) {
    instance.defaults.headers.common["Content-Type"] = "multipart/form-data";
  }

  //Exclude urlsWithoutBranchIdInRequest urls from Authenticated requests with BranchId

  // instance.defaults.headers.common['Authorization'] = 'Bearer';
  instance.defaults.headers.common["Accept"] = "application/json";
};

const request = ({
  url,
  http_method,
  data,
  headers,
  noStringify = false,
  responseType,
}) => {
  let bodyData;
  let service,
    lastRefreshTime,
    currenTimestamp,
    getToken = "";
  bodyData = noStringify ? JSON.stringify(data) : data;

  let skipTokenRefreshForUrls = ["/login", "/signup"],
    binaryUploadUrls = ["/UndergraduateFiles/UploadUndergraduatePassport"],
    refreshTokenUrl = "/refreshtoken",
    serviceToTest = url.split("api")[1];
  // serviceToTest = url.split("Fintech.Onboarding/api/BusinessProfile")[1];

  let loggedAccountInfo = localStorage.getItem("stuXAuth") || "{}";

  let stuAuthData = JSON.parse(loggedAccountInfo);

  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = axios.CancelToken.source();

  if (http_method.toLowerCase() === "get") {
    if (headers === undefined) {
      setTokenAuthorization(url);
    } else if (headers !== undefined) {
      for (let [key, value] of Object.entries(headers)) {
        instance.defaults.headers.common[key] = value;
      }
    }
    let serviceResponse = "",
      serviceResponse2 = "";

    if (url.indexOf(refreshTokenUrl) > -1) {
      // if (typeof cancelToken != typeof undefined) {
      //     cancelToken.cancel("Operation canceled due to new request.");
      // }
      // cancelToken = axios.CancelToken.source();
    }
    if (
      stuAuthData?.access_token &&
      skipTokenRefreshForUrls.indexOf(serviceToTest) === -1
    ) {
      lastRefreshTime = stuAuthData.lastLogForAuth;
      currenTimestamp = Date.now();

      service = instance.get(url, bodyData);
    } else {
      if (responseType === undefined) {
        service = instance.get(url, { cancelToken: cancelToken.token });
        // service = instance.get(url, bodyData);
      }
    }

    return service
      .then(function (response) {
        if (
          response.headers["content-type"].indexOf("application/json") > -1 ||
          response.headers["content-type"].indexOf("application/octet-stream") >
            -1
        ) {
          // return response;
          return service;
        } else {
          // serviceResponse = "An error occured";
          serviceResponse = Promise.reject(response);
          return serviceResponse;
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            let currentRoute = window.location.pathname,
              type = "unauthorized";

            Logout();
          } else if (
            error.response.status === 400 ||
            error.response.status > 401
          ) {
            if (serviceResponse !== "") {
              return serviceResponse;
            } else {
              return service;
            }
          } else {
            // return service;
            if (serviceResponse !== "") {
              return serviceResponse;
            } else {
              return service;
            }
          }
        }

        if (serviceResponse !== "") {
          return serviceResponse;
        } else {
          return service;
        }
      });
  }

  if (http_method.toLowerCase() === "post") {
    //check for header
    if (binaryUploadUrls.indexOf(serviceToTest) === -1) {
      instance.defaults.headers.common["Content-Type"] = "application/json";
    }
    if (binaryUploadUrls.indexOf(serviceToTest) > -1) {
      instance.defaults.headers.common["Content-Type"] = "multipart/form-data";
    }

    if (headers === undefined) {
      setTokenAuthorization(url);
    } else if (headers !== undefined) {
      for (let [key, value] of Object.entries(headers)) {
        instance.defaults.headers.common[key] = value;
      }
      setTokenAuthorization(url);
    }

    if (
      stuAuthData?.access_token &&
      skipTokenRefreshForUrls.indexOf(serviceToTest) === -1
    ) {
      lastRefreshTime = stuAuthData.lastLogForAuth;
      currenTimestamp = Date.now();

      service = instance.post(url, bodyData);
    } else {
      service = instance.post(url, bodyData, {
        cancelToken: cancelToken.token,
      });
    }

    return service
      .then(function (response) {
        return service;
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            Logout();
          } else if (
            error.response.status === 400 ||
            error.response.status > 401
          ) {
            return service;
          } else {
            return service;
          }
        }

        return service;
      });
  }

  if (http_method.toLowerCase() === "put") {
    //check for header
    if (binaryUploadUrls.indexOf(serviceToTest) === -1) {
      instance.defaults.headers.common["Content-Type"] = "application/json";
    }
    if (binaryUploadUrls.indexOf(serviceToTest) > -1) {
      instance.defaults.headers.common["Content-Type"] = "multipart/form-data";
    }

    if (headers === undefined) {
      setTokenAuthorization(url);
    } else if (headers !== undefined) {
      for (let [key, value] of Object.entries(headers)) {
        instance.defaults.headers.common[key] = value;
      }
    }

    if (
      stuAuthData?.access_token &&
      skipTokenRefreshForUrls.indexOf(serviceToTest) === -1
    ) {
      lastRefreshTime = stuAuthData.lastLogForAuth;
      currenTimestamp = Date.now();

      service = instance.put(url, bodyData, { cancelToken: cancelToken.token });

      // }
    } else {
      service = instance.put(url, bodyData);
    }
    return service
      .then(function (response) {
        return service;
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            Logout();
          } else if (
            error.response.status === 400 ||
            error.response.status > 401
          ) {
            return service;
          } else {
            return service;
          }
        }

        return service;
      });
  }

  if (http_method.toLowerCase() === "delete") {
    //check for header
    if (binaryUploadUrls.indexOf(serviceToTest) === -1) {
      instance.defaults.headers.common["Content-Type"] = "application/json";
    }
    if (binaryUploadUrls.indexOf(serviceToTest) > -1) {
      instance.defaults.headers.common["Content-Type"] = "multipart/form-data";
    }

    if (headers === undefined) {
      setTokenAuthorization(url);
    } else if (headers !== undefined) {
      for (let [key, value] of Object.entries(headers)) {
        instance.defaults.headers.common[key] = value;
      }
    }

    if (
      stuAuthData?.access_token &&
      skipTokenRefreshForUrls.indexOf(serviceToTest) === -1
    ) {
      lastRefreshTime = stuAuthData.lastLogForAuth;
      currenTimestamp = Date.now();

      service = instance.delete(url, bodyData, {
        cancelToken: cancelToken.token,
      });

      // }
    } else {
      service = instance.delete(url, bodyData);
    }
    return service
      .then(function (response) {
        return service;
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            Logout();
          } else if (
            error.response.status === 400 ||
            error.response.status > 401
          ) {
            return service;
          } else {
            return service;
          }
        }

        return service;
      });
  }
};

export const ApiService = {
  setTokenAuthorization,
  request,
};
