import { Link } from "react-router-dom";
import "./index.scss";

export const AlertMsg = ({ type, message }) => {
  return (
    <div className={`alert-msg ${type}`}>
      {type === "error" && (
        <div className="alert-txt">{message || "An Error Occured"} </div>
      )}
      {type === "success" && (
        <div className="alert-txt">{message || "Successful"} </div>
      )}

      {type === "error" && message.includes("pay") && (
        <div className="action_link">
          <Link to="/payment"> Click here to pay.</Link>
        </div>
      )}

      {type === "error" && message.indexOf("all fees") >= 1 && (
        <div className="action_link">
          <Link to="/undergraduate/dashboard">
            {" "}
            Click here to see pending fees{" "}
          </Link>
        </div>
      )}
    </div>
  );
};
