import React from "react";
import { InAppTemplate } from "../../components/templates/portal";
import UtmeInfo from "./UtmeInfo";

const CandidateInfo = () => {
  return (
    <InAppTemplate
      childComponent={<UtmeInfo />}
      pageTitle="PERSONAL INFORMATION"
    />
  );
};

export default CandidateInfo;
