import "./index.scss";
import { useSelector } from "react-redux";
import useWindowSize from "../../hooks/useWindowSize";
import { LoadingItem } from "../../components/loading";

const GenerateInvoice = () => {
  const { invoice, loadingInvoice } = useSelector(
    (state) => state.onboardingReducer
  );

  const screenResolution = useWindowSize();

  return (
    <>
      {loadingInvoice ? (
        <LoadingItem />
      ) : (
        <>
          <div className="payment_details">
            <div className="txtn_info_wrap">
              <div className="each_info_detail">
                <div className="info_title">TRANSACTION ID :</div>
                <div className="info_data">{invoice?.transactionId}</div>
              </div>
              <div className="each_info_detail">
                <div className="info_title">REGISTRATION NO :</div>
                <div className="info_data">{invoice?.matricNumber}</div>
              </div>

              <div className="each_info_detail">
                <div className="info_title">STUDENT NAME :</div>
                <div className="info_data">{invoice?.fullName}</div>
              </div>
              <div className="each_info_detail">
                <div className="info_title">SERVICE :</div>
                <div className="info_data">{invoice?.paymentType}</div>
              </div>
              <div className="each_info_detail">
                <div className="info_title">TOTAL AMOUNT PAYABLE :</div>
                <div className="info_data">{invoice?.amount}</div>
              </div>
              <div className="each_info_detail">
                <div className="info_title">PAYMENT STATUS :</div>
                <div className="info_data">{invoice?.paymentStatus}</div>
              </div>
            </div>
            {invoice?.paymentStatus !== "PAID" && (
              <div className="txtn_cta">
                <a
                  target="_blank"
                  className="btn"
                  href={invoice?.downloadUrl}
                  rel="noreferrer"
                >
                  Download Invoice
                </a>
              </div>
            )}

            {invoice?.paymentStatus === "PAID" &&
            screenResolution?.width <= 767 ? (
              <div className="txtn_cta">
                <a
                  target="_blank"
                  href={invoice?.compulsoryFeeHistories[0]?.downloadUrl}
                  className="download_cta btn"
                  rel="noreferrer"
                >
                  Download receipt
                </a>
              </div>
            ) : null}

            {invoice?.compulsoryFeeHistories.length >= 1 && (
              <div className="previous_payments">
                {screenResolution?.width >= 767 && (
                  <table>
                    <thead>
                      <tr>
                        <th className="first">
                          <span> GENERATED ON</span>
                        </th>
                        <th>
                          {" "}
                          <span>SESSION</span>
                        </th>
                        <th>
                          {" "}
                          <span>TRANSACTION ID</span>
                        </th>
                        <th>
                          {" "}
                          <span>PAYMENT STATUS</span>
                        </th>
                        <th>
                          {" "}
                          <span>AMOUNT</span>
                        </th>
                        <th className="last">
                          <span>DOWNLOAD</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoice?.compulsoryFeeHistories.map((txtn, index) => {
                        return (
                          <tr key={index}>
                            <td className="first">
                              <span>{txtn?.generatedOn}</span>
                            </td>
                            <td>
                              {" "}
                              <span>{txtn?.session}</span>
                            </td>
                            <td>
                              {" "}
                              <span>{txtn?.transactionId}</span>{" "}
                            </td>
                            <td>
                              {" "}
                              <span
                                className={
                                  txtn?.paymentStatus.toLowerCase() === "paid"
                                    ? "done"
                                    : "pending"
                                }
                              >
                                {txtn?.paymentStatus}
                              </span>{" "}
                            </td>
                            <td>
                              {" "}
                              <span>{txtn?.amount}</span>{" "}
                            </td>
                            <td className="last">
                              <span>
                                {" "}
                                <a
                                  target="_blank"
                                  href={txtn?.downloadUrl}
                                  className="download_cta btn"
                                  rel="noreferrer"
                                >
                                  Download Receipt
                                </a>
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default GenerateInvoice;
