import React, { useCallback, useEffect, useState } from "react";
import "./index.scss";
import DropDown from "../../components/dropdown";
import {
  A_LEVEL_RESULTS,
  CLASS_OF_GRADUATION,
  DEGREE_CLASS_OF_GRADUATION,
  grades,
  yearsArray,
} from "../../shared/_helpers/constants";
import { A_LEVEL_COURSE_LIST } from "../../shared/_helpers/aLevelCourselist";
import { institutions } from "../../shared/_helpers/institutions";
import InfoModal from "../../components/InfoModal";
import { addOrUpdateResult, getResults } from "../../redux/action/onboarding";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { LoadingItem } from "../../components/loading";

const AdvancedLevelResults = () => {
  const { candidateInfo, loadingResults, candidateResults } = useSelector(
    (state) => state.onboardingReducer
  );

  const [formValues, setFormValues] = useState({
    aLevelResultId: "",
    institutionName: "",
    name: "",
    yearOfIssue: "",
    resultType: "",
    course: "",
    degreeClass: "",
    subjectGrades: [
      { subject: "", grade: "" },
      { subject: "", grade: "" },
      { subject: "", grade: "" },
    ],
  });
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const {
      aLevelResultId,
      courseOfStudy,
      examYear,
      fullName,
      grade1,
      grade2,
      grade3,
      graduationClass,
      institution,
      subject1,
      subject2,
      subject3,
      type,
    } = candidateResults || {};
    setFormValues({
      institutionName: institution || "",
      aLevelResultId,
      name: fullName || "",
      yearOfIssue: examYear || "",
      resultType: type || "",
      course: courseOfStudy || "",
      degreeClass: graduationClass || "",
      subjectGrades: [
        { subject: subject1 || "", grade: grade1 || "" },
        { subject: subject2 || "", grade: grade2 || "" },
        { subject: subject3 || "", grade: grade3 || "" },
      ],
    });
  }, [candidateResults]);

  const [availableCourses, setAvailableCourses] = useState([]);

  const { registrationNumber } = candidateInfo || {};
  const dispatch = useDispatch();

  const formattedCourseList = A_LEVEL_COURSE_LIST.map((course) => ({
    label: course,
    value: course,
  }));

  const formattedGrades = grades.map((course) => ({
    label: course,
    value: course,
  }));

  const formattedInstitutions = institutions.map((course) => ({
    label: course,
    value: course,
  }));
  useEffect(() => {
    if (formValues.resultType === "NCE" && !formValues?.aLevelResultId) {
      setFormValues((prev) => ({
        ...prev,
        subjectGrades: [
          { subject: "EDUCATION", grade: "" },
          { subject: "", grade: "" },
          { subject: "", grade: "" },
        ],
      }));
    }
  }, [formValues.resultType, formValues.aLevelResultId]);

  useEffect(() => {
    if (formValues.resultType === "IJMB") {
      const selectedSubjects = formValues.subjectGrades
        .map((sg) => sg.subject)
        .filter(Boolean);

      setAvailableCourses(
        formattedCourseList.filter(
          (course) => !selectedSubjects.includes(course.value)
        )
      );
    } else if (formValues.resultType === "NCE") {
      setAvailableCourses(
        formattedCourseList.filter((course) => course.value !== "EDUCATION")
      );
    } else {
      setAvailableCourses(formattedCourseList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.subjectGrades, formValues.resultType]);

  const handleChange = (field, value) => {
    if (field === "resultType") {
      setFormValues({
        ...formValues,
        resultType: value,
        course: "",
        degreeClass: "",
        subjectGrades: [
          { subject: "", grade: "" },
          { subject: "", grade: "" },
          { subject: "", grade: "" },
        ],
      });
    } else {
      setFormValues((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
  };

  const handleSubjectGradeChange = (index, field, value) => {
    setFormValues((prev) => {
      const newSubjectGrades = [...prev.subjectGrades];
      newSubjectGrades[index] = {
        ...newSubjectGrades[index],
        [field]: value,
      };
      return { ...prev, subjectGrades: newSubjectGrades };
    });
  };

  const validateForm = useCallback(() => {
    const {
      institutionName,
      yearOfIssue,
      resultType,
      course,
      degreeClass,
      subjectGrades,
      name,
    } = formValues;

    if (
      !institutionName ||
      !name ||
      !yearOfIssue ||
      !resultType ||
      (!["IJMB", "NCE"].includes(resultType) && !course) ||
      (!["IJMB", "NCE"].includes(resultType) && !degreeClass) ||
      (["IJMB", "NCE"].includes(resultType) &&
        subjectGrades.some((sg) => !sg.subject || !sg.grade))
    ) {
      setShowErrorModal(true);
      return false;
    } else if (
      resultType === "IJMB" &&
      new Set(subjectGrades.map((sg) => sg.subject)).size !==
        subjectGrades.length
    ) {
      alert("Subjects cannot be duplicated for IJMB.");
      setShowErrorModal(true);
      return false;
    }
    setShowErrorModal(false);
    return true;
  }, [formValues]);

  const handleSubmit = async () => {
    const {
      course,
      subjectGrades,
      institutionName,
      name,
      degreeClass,
      yearOfIssue,
      resultType,
    } = formValues;

    if (validateForm()) {
      const payload = {
        aLevelResultId: "",
        registrationNumber,
        fullName: name,
        courseOfStudy: course,
        graduationClass: degreeClass,
        subject1: subjectGrades[0]?.subject,
        subject2: subjectGrades[1]?.subject,
        subject3: subjectGrades[2]?.subject,
        grade1: subjectGrades[0]?.grade,
        grade2: subjectGrades[1]?.grade,
        grade3: subjectGrades[2]?.grade,
        institution: institutionName,
        examYear: String(yearOfIssue),
        type: resultType,
      };
      setLoading(true);
      const response = await addOrUpdateResult(payload, "ALEVEL");
      if (response.status === 200) {
        setLoading(false);
        dispatch(getResults(registrationNumber, "ALEVEL"));
        setLoading(false);
        return toast.success("Results records updated successfully");
      }
    }
  };

  useEffect(() => {
    dispatch(getResults(registrationNumber, "ALEVEL"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="advanced-level-results">
      {loadingResults ? (
        <LoadingItem />
      ) : (
        <>
          {" "}
          <InfoModal
            show={showErrorModal}
            setShow={setShowErrorModal}
            message="Please fill all required fields."
          />
          <p className="notice">
            Important Notes On A Level Result Submission: Please ensure to enter
            and submit your A Level result accurately as this will be validated
            and use to process your application for admission. Ensure all
            information are same as on the result being submitted.
          </p>
          <div className="heading">
            <h4>Basic Information</h4>
            <hr />
          </div>
          <div className="field">
            <label>
              Name of Institution<span className="required-field">*</span>
            </label>

            <DropDown
              field="institutionName"
              options={formattedInstitutions}
              value={{
                label: formValues.institutionName,
                value: formValues.institutionName,
              }}
              label="Institution"
              handleChange={(value) => handleChange("institutionName", value)}
              className="year-field"
              placeholder="Select Institution"
              borderColor="#000000"
            />
          </div>
          <div className="name-year-field">
            <div className="name-field">
              <label>
                Name of Candidate as Written on ALevel Result{" "}
                <span className="required-field">*</span>
              </label>
              <input
                type="text"
                value={formValues.name}
                onChange={(e) =>
                  handleChange("name", e.target.value.toUpperCase())
                }
              />
            </div>

            <div className="year-field">
              <label>
                Year of issue<span className="required-field">*</span>
              </label>
              <DropDown
                field="yearOfIssue"
                options={yearsArray}
                label="Year"
                value={{
                  label: formValues.yearOfIssue,
                  value: formValues.yearOfIssue,
                }}
                // handleChange={setYear}
                handleChange={(value) => handleChange("yearOfIssue", value)}
                className="year-field"
                placeholder="Year..."
                borderColor="#000000"
              />
            </div>
          </div>
          <div className="heading">
            <h4>A Level Result Entry</h4>
            <hr />
          </div>
          <div className="result-info">
            <div className="result-type">
              <label>
                Type of A’Level Result<span className="required-field">*</span>
              </label>
              <DropDown
                field="resultType"
                options={A_LEVEL_RESULTS}
                value={{
                  label: formValues.resultType,
                  value: formValues.resultType,
                }}
                label="Result Type"
                handleChange={(value) => handleChange("resultType", value)}
                className="year-field"
                placeholder="Select result type"
                borderColor="#000000"
              />
            </div>

            {!["NCE", "IJMB"].includes(formValues?.resultType) ? (
              <>
                <div className="result-type">
                  <label>
                    Course<span className="required-field">*</span>
                  </label>
                  <DropDown
                    field="course"
                    options={formattedCourseList}
                    value={{
                      label: formValues.course,
                      value: formValues.course,
                    }}
                    label="Course"
                    handleChange={(value) => handleChange("course", value)}
                    className="year-field"
                    placeholder="Course"
                    borderColor="#000000"
                  />
                </div>

                <div className="class-of-degree-field">
                  <label>
                    Class of degree<span className="required-field">*</span>
                  </label>
                  <DropDown
                    field="degreeClass"
                    options={
                      ["HND", "ND", "OND", "AD"].includes(formValues.resultType)
                        ? CLASS_OF_GRADUATION
                        : DEGREE_CLASS_OF_GRADUATION
                    }
                    value={{
                      label: formValues.degreeClass,
                      value: formValues.degreeClass,
                    }}
                    label="Class of Degree"
                    handleChange={(value) => handleChange("degreeClass", value)}
                    className="class"
                    placeholder="Class of degrree"
                    borderColor="#000000"
                  />
                </div>
              </>
            ) : null}
          </div>
          {["NCE", "IJMB"].includes(formValues?.resultType) ? (
            <div className="subjects-with-grade">
              {formValues.subjectGrades.map((sg, index) => (
                <div className="each-subject" key={index}>
                  <label>
                    {" "}
                    {formValues.resultType === "NCE" && index > 0
                      ? `Major ${index}`
                      : `Subject ${index + 1}`}
                  </label>
                  <DropDown
                    field={`subject-${index}`}
                    options={
                      formValues.resultType === "NCE" && index === 0
                        ? [{ label: "EDUCATION", value: "EDUCATION" }]
                        : availableCourses
                    }
                    value={
                      formValues.resultType === "NCE" && index === 0
                        ? [{ label: "EDUCATION", value: "EDUCATION" }]
                        : [
                            {
                              label: formValues?.subjectGrades[index]?.subject,
                              value: formValues?.subjectGrades[index]?.subject,
                            },
                          ]
                    }
                    // label={
                    //   formValues.resultType === "NCE" && index > 0
                    //     ? `Major ${index}`
                    //     : `Subject ${index + 1}`
                    // }
                    handleChange={(value) =>
                      handleSubjectGradeChange(index, "subject", value)
                    }
                    className="subject-options"
                    placeholder="Select subject"
                    borderColor="#000000"
                    disabled={formValues.resultType === "NCE" && index === 0}
                  />
                  <label>Grade {index + 1}</label>
                  <DropDown
                    field={`grade-${index}`}
                    options={formattedGrades}
                    label={`Grade ${index + 1}`}
                    value={[
                      {
                        label: formValues?.subjectGrades[index]?.grade,
                        value: formValues?.subjectGrades[index]?.grade,
                      },
                    ]}
                    handleChange={(value) =>
                      handleSubjectGradeChange(index, "grade", value)
                    }
                    className="grade-field"
                    placeholder="Grade"
                    borderColor="#000000"
                  />
                </div>
              ))}
            </div>
          ) : null}
          <div className="a-level-submit-btn">
            {" "}
            <button onClick={handleSubmit} disabled={loading}>
              {loading && !formValues?.aLevelResultId
                ? "SUBMITTING A LEVEL RESULT"
                : !loading && formValues?.aLevelResultId
                ? "UPDATE A LEVEL RESULT"
                : loading && formValues?.aLevelResultId
                ? "UPDATING A LEVEL RESULT..."
                : "SUBMIT MY A LEVEL RESULT"}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default AdvancedLevelResults;
