import React from "react";
import "./index.scss";
import { LoadingItem } from "../../components/loading";

const PrintAcknowledgement = ({ url, loading, error }) => {
  return (
    <div className="download-container">
      {loading ? (
        <LoadingItem />
      ) : error?.length ? (
        <p className="alert print-error">{error}</p>
      ) : url.length ? (
        <div className="alert success-alert">
          You can now{" "}
          <a className="dl_link" target="_blank" href={url} rel="noreferrer">
            download the acknowledgement slip
          </a>{" "}
        </div>
      ) : null}
    </div>
  );
};

export default PrintAcknowledgement;
