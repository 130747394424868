import React from "react";
import AdvancedLevelResults from "./AdvancedLevelResults";
import { InAppTemplate } from "../../components/templates/portal";

const ALevelResults = () => {
  return (
    <InAppTemplate
      childComponent={<AdvancedLevelResults />}
      pageTitle="A'LEVEL RESULTS SUBMISSION"
    />
  );
};

export default ALevelResults;
